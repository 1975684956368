import { isBefore, isAfter, addDays } from 'date-fns';
import { ValidationDataModel } from '@sitecore-jss/sitecore-jss-forms';
import { setReplaceText } from '../utils';

export const getFutureDate = (daysFromToday: number) => {
  const today = new Date();
  return addDays(today, Number(daysFromToday));
};

export const minDateValidator = (
  model: ValidationDataModel | undefined,
  inputValue: Date,
  minDaysInFuture: number | undefined
): boolean | string => {
  if (!inputValue || !model || !minDaysInFuture) {
    return true;
  }

  const inputDate = new Date(inputValue);
  const minDate = getFutureDate(minDaysInFuture);

  if (isBefore(inputDate, minDate)) {
    return setReplaceText(model.message, { '{0}': `${minDaysInFuture}` });
  }

  return true;
};

export const maxDateValidator = (
  model: ValidationDataModel | undefined,
  inputValue: Date,
  maxDaysInFuture: number | undefined
): boolean | string => {
  if (!inputValue || !model || !maxDaysInFuture) {
    return true;
  }

  const inputDate = new Date(inputValue);
  const maxDate = getFutureDate(maxDaysInFuture);

  if (isAfter(inputDate, maxDate)) {
    return setReplaceText(model.message, { '{0}': `${maxDaysInFuture}` });
  }

  return true;
};
