import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'src/jss/lib/component-props';
import { ButtonProps, SitecoreImage } from '@alliander-fe/sitecore-types';
import { LocationOutages } from 'src/components/LocationOutages';

export type OutagesOnLocationProps = ComponentProps & {
  fields: {
    onlyShowRecentlyResolvedOutages?: { value?: boolean };
    linkOne?: { value?: ButtonProps };
    linkTwo?: { value?: ButtonProps };
    noOutagesLinkOne?: { value?: ButtonProps };
    noOutagesLinkTwo?: { value?: ButtonProps };
    image?: { value?: SitecoreImage };
    title?: { value?: string };
    outageDetailsModalText?: { value?: string };
    outageDetailsModalLinkOne?: { value?: ButtonProps };
    outageDetailsModalLinkTwo?: { value?: ButtonProps };
  };
};

const OutagesOnLocation = (props: OutagesOnLocationProps) => {
  if (props.fields) {
    return <LocationOutages {...props.fields} />;
  }

  return null;
};

export default withDatasourceCheck()<OutagesOnLocationProps>(OutagesOnLocation);
