import { LinkFieldValue, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { useRouter } from 'next/router';

import { ComponentProps } from 'src/jss/lib/component-props';

import { forceArray, slugify, createFilterMapper } from 'src/utils';
import { Filter } from 'src/types';

import { Box, Heading, PageGrid, GridItem, Card, Table, Dropdown } from '@ads-core/components';
import NextLink from 'next/link';
import { queryParams } from './NewsOverview';

type ProjectOverviewProps = {
  fields: {
    items: {
      displayName: string;
      fields: {
        city: { value: string };
        detailPage: { value: LinkFieldValue };
        endDate: { value: string };
        startDate: { value: string };
        typeOfWork: { value: string };
        workingArea: { value: string };
        regions: Filter[];
      };
      id: string;
      name: string;
      url: string;
    }[];
    title: { value: string };
    regionsFilter: Filter[];
  };
  params: { anchor: string };
} & ComponentProps;

const ProjectOverview = (props: ProjectOverviewProps) => {
  const router = useRouter();
  const { regionsFilter, items } = props.fields;
  const regionMapper = createFilterMapper(regionsFilter, 'id');
  const region = forceArray(router.query[queryParams.region])[0] || '';
  const regionFilterValue = regionMapper[region] || '';

  // Filter the items based on the region query param
  const filteredItems = regionFilterValue
    ? items.filter((item) => item.fields.regions.map((r) => r.id).includes(regionFilterValue))
    : items;

  const currentFilterName = regionsFilter.find((item) => item.id === regionFilterValue)?.name;

  return (
    <PageGrid>
      {props.fields.title ? (
        <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
          <Box paddingBottom={{ initial: 4, md: 2 }} asChild>
            <Heading size="h1">{props.fields.title.value}</Heading>
          </Box>
        </GridItem>
      ) : null}
      <GridItem columnEnd="1" columnStart="-1">
        <Card>
          <Box paddingBottom={10} width={{ md: '33%' }}>
            <Dropdown
              placeholder="Kies een regio"
              label="Filter op regio"
              name="regio"
              items={[
                { label: 'Toon alle', value: '' },
                ...props.fields.regionsFilter.map((region) => {
                  return {
                    label: region.displayName,
                    value: region.id,
                  };
                }),
              ]}
              value={regionFilterValue}
              onValueChange={(value) => {
                const selectedItem = props.fields.regionsFilter.find((item) => item.id === value);

                router.push({
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    [queryParams.region]: selectedItem ? slugify(selectedItem.displayName) : '',
                  },
                });
              }}
            />
          </Box>
          {filteredItems.length ? (
            <Table
              title="Projectenoverzicht"
              columns={[
                { dataKey: 'city', title: 'Plaats' },
                { dataKey: 'workingArea', title: 'Werkgebied' },
                { dataKey: 'typeOfWork', title: 'Soort werkzaamheden' },
                { dataKey: 'date', title: 'Start-en einddatum' },
              ]}
              data={filteredItems.map((item) => {
                const { city, endDate, startDate, typeOfWork, workingArea, detailPage } =
                  item.fields;
                return {
                  fields: {
                    city: city.value,
                    date: `${startDate.value} - ${endDate.value}`,
                    typeOfWork: typeOfWork.value,
                    workingArea: workingArea.value,
                  },
                  onRowClick: () => {
                    if (detailPage.value.href) {
                      router.push(detailPage.value.href);
                    }
                  },
                  link: detailPage.value.href ? (
                    <NextLink href={detailPage.value.href} />
                  ) : undefined,
                };
              })}
            />
          ) : (
            <Heading size="h5" as="h3">
              Geen resultaten gevonden voor <b>{currentFilterName}</b>
            </Heading>
          )}
        </Card>
      </GridItem>
    </PageGrid>
  );
};

export default withDatasourceCheck()<ComponentProps>(ProjectOverview);
