import React from 'react';
import clsx from 'clsx';
import { Heading } from '../Heading';
import { Tag } from '../Tag';
import { Text } from '../Text';
import { Stack } from '../Stack';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Box } from '../Box';
import { TextLink } from '../TextLink';
import { useDialogContext } from '../../providers';
import { VisuallyHidden } from '../VisuallyHidden';
import { CombineProps } from '../../types/type-helpers';
import { CheckmarkIcon } from '../../icons';
import { DialogWithContent } from '../Dialog';

import * as styles from './FollowUpSteps.css';

export type FollowUpStepCardType = 'customer' | 'company' | 'checked';

export type FollowUpStepProps = {
  moreDetails?: boolean;
  moreDetailsText?: string;
  moreDetailsTitle?: string;
  moreDetailsTag?: string;
  moreDetailsButton?: { href?: string; text?: string };
  text?: string;
  title?: string;
  tag?: string;
  type?: FollowUpStepCardType;
  container?: React.ReactNode;
  paragraph?: string;
};

export type FollowUpStepsProps = {
  tag?: string;
  title?: string;
  conclusion?: React.ReactNode;
  followUpSteps: FollowUpStepProps[];
};

export type FollowUpStepsSitecoreProps = {
  tag?: React.ReactElement;
  title?: React.ReactElement;
  conclusion?: React.ReactNode;
  followUpSteps: {
    moreDetails?: boolean;
    moreDetailsText?: string;
    moreDetailsTitle?: string;
    moreDetailsTag?: string;
    moreDetailsButton?: { href?: string; text?: string };
    text?: React.ReactElement;
    title?: React.ReactElement;
    tag?: React.ReactElement;
    type?: FollowUpStepCardType;
    container?: React.ReactNode;
    paragraph?: string;
  }[];
};

type FollowUpStepsViewProps = CombineProps<
  FollowUpStepsProps,
  FollowUpStepsSitecoreProps
>;

export const FollowUpSteps = (props: FollowUpStepsProps) => {
  return <FollowUpStepsView {...props} />;
};

export const FollowUpStepsSitecore = (props: FollowUpStepsSitecoreProps) => {
  return <FollowUpStepsView {...props} />;
};

export const FollowUpStepsView = ({
  tag,
  title,
  followUpSteps,
  conclusion,
}: FollowUpStepsViewProps) => {
  const hasConclusion = conclusion !== undefined;

  return (
    <>
      {tag || title ? (
        <PageGrid>
          {tag ? (
            <GridItem
              columnStart={{ initial: '1', lg: '3' }}
              columnEnd={{ initial: '-1', lg: '-3' }}
            >
              <Stack alignX="center" isFullWidth>
                <Box paddingBottom={{ initial: 4, lg: 3 }}>
                  <Tag asChild>{tag}</Tag>
                </Box>
              </Stack>
            </GridItem>
          ) : null}
          {title ? (
            <GridItem
              columnStart={{ initial: '1', lg: '3' }}
              columnEnd={{ initial: '-1', lg: '-3' }}
            >
              <Stack alignX="center" isFullWidth>
                <Heading size="h2" align="center" asChild>
                  {title}
                </Heading>
              </Stack>
            </GridItem>
          ) : null}
        </PageGrid>
      ) : null}
      {followUpSteps.length ? (
        <Cards followUpSteps={followUpSteps} hasConclusion={hasConclusion} />
      ) : null}
      {conclusion ? (
        <PageGrid>
          <GridItem
            columnStart={{ initial: '1', lg: '2' }}
            columnEnd={{ initial: '-1', lg: '-2' }}
          >
            <Box className={styles.conclusionBlock}>{conclusion}</Box>
          </GridItem>
        </PageGrid>
      ) : null}
    </>
  );
};

type FollowUpStepsCardsProps = FollowUpStepsViewProps & {
  hasConclusion: boolean;
};

export const Cards = (props: FollowUpStepsCardsProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const [selectedCardIndex, setSelectedCardIndex] = React.useState<
    number | null
  >(null);

  const tagColor = (variant?: FollowUpStepCardType) => {
    switch (variant) {
      case 'checked':
        return 'green';
      case 'customer':
        return 'blue';
      default:
        return 'purple';
    }
  };

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedCardIndex(null);
    }
  }, [isOpen]);

  return (
    <PageGrid>
      <GridItem columnStart="1" columnEnd="-1">
        <Stack
          direction={'column'}
          className={clsx(
            styles.cardsWrapper,
            props.followUpSteps[0].type === 'customer' ? 'customer' : 'company'
          )}
          isFullWidth
        >
          {props.followUpSteps.map((card, idx) => (
            <Box
              key={idx}
              padding={12}
              paddingTop={14}
              className={styles.cardRecipe({
                hasConclusion: props.hasConclusion,
              })}
              bg="backgroundLight"
              borderRadius="md"
              asChild
            >
              <Box
                className={styles.counter({
                  variant: card.type,
                  index: idx % 2 ? 'even' : 'odd',
                })}
              >
                {card.type === 'checked' ? <CheckmarkIcon /> : idx + 1}
              </Box>
              <Stack alignY="justify">
                <Box asChild>
                  <Stack gap={8}>
                    {card.tag ? (
                      <Tag
                        asChild
                        className={styles.tag}
                        backgroundColor={tagColor(card.type)}
                      >
                        {card.tag}
                      </Tag>
                    ) : null}
                    {card.title ? (
                      <Text size="label" asChild>
                        {card.title}
                      </Text>
                    ) : null}
                    {card.text ? (
                      <Heading size="h5" asChild>
                        {card.text}
                      </Heading>
                    ) : null}
                    {card.container || card.paragraph ? (
                      <Stack gap={6}>
                        {card.container ? card.container : null}
                        {card.paragraph ? (
                          <Text size="paragraph" asChild>
                            {card.paragraph}
                          </Text>
                        ) : null}
                      </Stack>
                    ) : null}
                    {card.moreDetails ? (
                      <>
                        <TextLink asChild>
                          <button
                            onClick={() => {
                              setSelectedCardIndex(idx);
                              openDialog();
                            }}
                          >
                            Toon details{' '}
                            <VisuallyHidden>voor {card.title}</VisuallyHidden>
                          </button>
                        </TextLink>
                        {selectedCardIndex === idx && (
                          <DialogWithContent
                            title={card.moreDetailsTitle ?? undefined}
                            description={card.moreDetailsText ?? undefined}
                            tag={card.moreDetailsTag ?? undefined}
                            button={card.moreDetailsButton ?? undefined}
                          />
                        )}
                      </>
                    ) : null}
                  </Stack>
                  <div
                    className={clsx(
                      styles.dashedLine,
                      card.type === 'customer'
                        ? 'customerDashedLine'
                        : 'companyDashedLine',
                      idx % 2 ? 'even' : 'odd'
                    )}
                  />
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
      </GridItem>
    </PageGrid>
  );
};
