import { Box } from '../Box';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { InputGroup } from '../InputGroup';
import { InputNumber } from '../InputNumber';
import { InputText } from '../InputText';
import { Stack } from '../Stack';
import { StackItem } from '../StackItem';
import {
  PostalCodeTaskVariants,
  postalCodeTask,
} from './PostalCodeCheckForm.css';

export type InputFields = {
  houseNumber?: string;
  postalCode: string;
  addition?: string;
};

export type PostalCodeCheckFormProps = {
  title: string;
  postalCode: string;
  postalCodeError?: string;
  houseNumberAddition?: string;
  houseNumberAdditionError?: string;
  submitButton: string;
  handleOnSubmit: (val: InputFields) => void;
  onlyPostalCode?: boolean;
} & PostalCodeTaskVariants;

export const PostalCodeCheckForm = ({
  handleOnSubmit,
  title,
  postalCode,
  postalCodeError,
  houseNumberAddition,
  houseNumberAdditionError,
  submitButton,
  color = 'purple',
  onlyPostalCode = false,
}: PostalCodeCheckFormProps) => {
  return (
    <Box
      paddingTop={{ initial: 10, lg: 12 }}
      paddingBottom={{ initial: 10, lg: 12 }}
      paddingLeft={{ initial: 10, lg: 12 }}
      paddingRight={{ initial: 10, lg: 12 }}
      asChild
    >
      <Stack
        className={postalCodeTask({ color })}
        alignX={{ lg: 'justify' }}
        alignY={{ lg: 'justify' }}
        direction={{ initial: 'column' }}
        gap={4}
        asChild
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const data = new FormData(e.target as HTMLFormElement);
            const houseNumber = data.get('houseNumber');
            const postalCode = data.get('postalCode');
            const addition = data.get('addition');

            handleOnSubmit({
              houseNumber: houseNumber as string,
              postalCode: postalCode as string,
              addition: addition as string,
            });
          }}
        >
          <StackItem asChild grow>
            <Heading
              size={{ initial: 'description', md: 'paragraph' }}
              fontWeight="semiBold"
              as="h2"
              color="onDark"
              isResponsive={false}
            >
              {title}
            </Heading>
          </StackItem>
          <Stack gap={2} direction={'column'}>
            <InputText
              label={postalCode}
              name="postalCode"
              tone="onDark"
              placeholder="1234AB"
              error={postalCodeError}
            />
            {!onlyPostalCode ? (
              <InputGroup
                label={houseNumberAddition}
                error={houseNumberAdditionError}
                tone="onDark"
              >
                <Stack gap={4} direction="row" isFullWidth>
                  <StackItem grow>
                    <InputNumber
                      label={'Huisnummer'}
                      name="houseNumber"
                      placeholder="10"
                      tone="onDark"
                    />
                  </StackItem>
                  <StackItem grow>
                    <InputText
                      label={'Toevoeging'}
                      name="addition"
                      placeholder="A"
                      tone="onDark"
                    />
                  </StackItem>
                </Stack>
              </InputGroup>
            ) : null}
          </Stack>
          <Stack alignX="center" alignY="center" asChild>
            <Button
              size="large"
              tone="onDark"
              variant="secondary"
              type="submit"
            >
              {submitButton}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
