import { ArrowRightIcon } from '../../icons';
import { Box } from '../Box';
import { ButtonLink } from '../ButtonLink';
import { RichText } from '../RichText';
import { Stack } from '../Stack';

type ConclusionLink = {
  href: string;
  text: string;
  anchor?: string;
  linktype?: string;
  class?: string;
  target?: string;
  querystring?: string;
  id?: string;
};

export type ConclusionBlockProps = {
  topText?: string;
  bottomText?: string;
  firstPrimaryButton?: ConclusionLink;
  firstSecondaryButton?: ConclusionLink;
  secondPrimaryButton?: ConclusionLink;
  secondSecondaryButton?: ConclusionLink;
};

export const ConclusionBlock = ({
  topText,
  bottomText,
  firstPrimaryButton,
  firstSecondaryButton,
  secondPrimaryButton,
  secondSecondaryButton,
}: ConclusionBlockProps) => {
  return (
    <Box
      bg="containerPrimary"
      borderRadius="brandLg"
      padding={{ initial: 10, md: 24 }}
      paddingBottom={{ initial: 10, md: 20 }}
      paddingTop={{ initial: 10, md: 20 }}
      asChild
    >
      <Stack gap={10}>
        {topText 
          ? <RichText tone="onDark">{topText}</RichText>
          : null
        }
        <ButtonConclusionBlock
          primaryButton={firstPrimaryButton}
          secondaryButton={firstSecondaryButton}
        />
        {bottomText
          ? <RichText tone="onDark">{bottomText}</RichText>
          : null
        }
        <ButtonConclusionBlock 
          primaryButton={secondPrimaryButton} 
          secondaryButton={secondSecondaryButton}
        />
      </Stack>
    </Box>
  );
};

export const ButtonConclusionBlock = ({primaryButton, secondaryButton}: { primaryButton?: ConclusionLink, secondaryButton?: ConclusionLink }) => {
  if(!primaryButton && !secondaryButton) return null;

  return (
    <Stack direction="row" wrap gap={4}>
      {primaryButton ? (
        <ButtonLink
          variant="secondary"
          href={primaryButton.href}
          target={primaryButton.target}
          id={primaryButton.id}
          afterIcon={<ArrowRightIcon size="xs" />}
          asChild
        >
          {primaryButton.text}
        </ButtonLink>
      ) : null}
      {secondaryButton ? (
        <ButtonLink
          variant="ghostOnDark"
          href={secondaryButton.href}
          target={secondaryButton.target}
          id={secondaryButton.id}
          afterIcon={<ArrowRightIcon size="xs" />}
          asChild
        >
          {secondaryButton.text}
        </ButtonLink>
      ) : null}
  </Stack>
  )
}