import { debounce } from '@sitecore-search/common';
import React from 'react';
import { WidgetDataType, widget, useSearchResultsQuery, useQuery } from '@sitecore-search/react';
import {
  trackSearchResultsFacetClickEvent,
  trackSearchResultsClickEntityEvent,
} from '@sitecore-search/data';

import {
  PageGrid,
  GridItem,
  Heading,
  Stack,
  Box,
  Pagination,
  Checkbox,
  Text,
  Tag,
  AnimatedArrowComponent,
  VisuallyHidden,
  RichText,
  ToneFocus,
} from '@ads-core/components';

import { forceArray, slugify } from 'src/utils';

import { useRouter } from 'next/router';
import { SearchInput } from 'src/components/SearchInput';
import * as styles from 'src/components/SearchResults/SearchResults.css';
import { useTrackingContext } from '@liander/context';
import { queryParams } from './MeterManuals';

const FilterItem = ({
  label,
  filterKey,
  onChange,
}: {
  label: string;
  filterKey: string;
  onChange?({ label, slug }: { label: string; slug: string }): void;
}) => {
  const value = label.toLowerCase();
  const router = useRouter();

  const existingFilters = router.query[filterKey] || [];
  const filters = forceArray(existingFilters);
  const isChecked = filters.includes(slugify(value));
  return (
    <React.Fragment key={filterKey}>
      <Checkbox
        checked={isChecked}
        label={label}
        onCheckedChange={(checked) => {
          const slugifiedLabel = slugify(label);

          if (onChange) {
            onChange({ label, slug: slugifiedLabel });
          }

          const newFilters = checked
            ? [...filters, slugifiedLabel]
            : filters.filter((cat) => cat !== slugifiedLabel);

          delete router.query[queryParams.page];

          router.push({
            query: {
              ...router.query,
              [filterKey]: newFilters,
            },
          });
        }}
      />
    </React.Fragment>
  );
};

export const SearchResultsWithInputComponent = (props: { rfkId: string }) => {
  const router = useRouter();
  const queryPage = router.query[queryParams.page];
  const urlKeyphrase = forceArray(router.query.term).join('');
  const page = queryPage ? Number(queryPage) : 1;
  const itemsPerPage = urlKeyphrase ? 8 : 0;
  const { trackSearchChange } = useTrackingContext();

  // We only hide the breadcrumb on the search page, so we add a class to the body
  // This isn't pretty, but what can you do
  React.useEffect(() => {
    const className = styles.isSearchPage;
    document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, []);

  const filters = React.useMemo(
    () => Object.keys(router.query).filter((key) => key !== 'path'),
    [router.query]
  );
  const facetFilters = React.useMemo(
    () => filters.filter((key) => ![queryParams.page, queryParams.term].includes(key)),
    [filters]
  );

  const searchQuery = useSearchResultsQuery();

  // Only execute query when theres a term
  searchQuery.isEnabled = () => !!urlKeyphrase;

  const hasFilters = !!filters.length;

  React.useEffect(() => {
    if (!hasFilters) {
      searchQuery.getRequest().setSearchLimit(0);
      return;
    }

    searchQuery
      .getRequest()
      .resetSearchFacetTypeFilters() // change in keyphrase resets facets
      .setSearchQueryKeyphrase(urlKeyphrase)
      .setSearchLimit(itemsPerPage)
      .setSearchOffset(0); // change in keyphrase resets page
  }, [urlKeyphrase, searchQuery, itemsPerPage, hasFilters]);

  React.useEffect(() => {
    if (!hasFilters) {
      searchQuery.getRequest().setSearchLimit(0);
      return;
    }

    const offset = (page - 1) * itemsPerPage;
    searchQuery.getRequest().setSearchOffset(offset);
  }, [itemsPerPage, page, searchQuery, hasFilters]);

  const facetResult = useQuery(searchQuery);
  const memoizedFacet = React.useMemo(() => facetResult.data, [facetResult]);

  // Filer on the facets
  React.useEffect(() => {
    if (!memoizedFacet) {
      return;
    }

    // Als er geen page parameter inzit, dan altijd resetten
    if (!filters.includes(queryParams.page)) {
      searchQuery.getRequest().setSearchOffset(0);
    }

    const apiFacets = memoizedFacet.facet || [];

    // Get all the facet "categories"
    const facetKeys = apiFacets.map((f) => f.name);

    // Get the query params from the url that are in the facets that we got from the API
    const filteredKeys = Object.keys(router.query).filter((q) => facetKeys.includes(q));

    // Map all the relevant values from the url into a single array, we need to do this because we can have multiple values for a single facet
    const relevantValues = filteredKeys.flatMap((k) => router.query[k]);

    // Creating an array of ids by crossmatching the values from the url with the facets from the API
    const relevantFacets = apiFacets
      .filter((f) => filteredKeys.includes(f.name))
      .flatMap((f) => f.value)
      // We've flatmapped, so now were getting data from the nested value property
      .filter((f) => relevantValues.includes(slugify(f.text)))
      .map((f) => f.id);

    // If there are facets we need to filter on in the url, do it
    if (relevantFacets.length) {
      searchQuery.getRequest().setSearchFacetType('segment', {
        filter: {
          type: 'or',
          values: relevantFacets,
        },
      });
    } else {
      // If not, it means the user has paginated without filters or has removed all filters
      // If the user is paginating (page in the url), we dont want to reset the offset. So only reset the offset when no page is in the url
      if (!queryPage) {
        searchQuery.getRequest().setSearchOffset(0);
      }

      // Always reset the filters when there are no filters in the url
      searchQuery.getRequest().resetSearchFacetTypeFilters();
    }
  }, [facetFilters, filters, searchQuery, memoizedFacet, router.query, hasFilters, queryPage]);

  const result = useQuery(searchQuery);
  const { isFetching, isSuccess } = result;
  const articles = result.data?.content || [];
  const facets = React.useMemo(() => result.data?.facet || [], [result?.data?.facet]);
  const keyphrase = urlKeyphrase ? urlKeyphrase : '';
  const totalItems = result.data?.total_item || 0;

  const keyphraseChangeFn = debounce((e) => {
    delete router.query[queryParams.page];

    if (e.target.value === '') {
      delete router.query[queryParams.term];
    }

    // When the term changes, we remove all query params (filtering) from the url, because filtering can only be applied AFTER a search
    router.push({
      pathname: router.pathname,
      query: {
        path: router.query.path,
        [queryParams.term]: e.target.value,
      },
    });
  }, 1000);

  const totalPages = React.useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [totalItems, itemsPerPage]
  );

  // These are the categories we render. We use the `name` as a filterKey, so we include it with every entry
  const filterTypes = React.useMemo(
    () =>
      facets.flatMap((facet) =>
        facet.value.map((f) => ({ ...f, name: facet.name, label: facet.label }))
      ),
    [facets]
  );

  const segment = router.query['segment'];

  React.useEffect(() => {
    if (result.isSuccess) {
      const trackedSegment = segment ? forceArray(segment).join(', ') : undefined;
      trackSearchChange({ keyphrase, segment: trackedSegment, totalItems });
    }
  }, [keyphrase, result.isSuccess, segment, totalItems, trackSearchChange]);

  return (
    <div>
      <PageGrid>
        <GridItem
          columnEnd={{ initial: '-1', md: '-3', lg: '-4' }}
          columnStart={{ initial: '1', md: '3', lg: '4' }}
        >
          <Box asChild width="100%" paddingTop={6} paddingBottom={6}>
            <Heading size="h1" align="center">
              {keyphrase ? `U heeft gezocht op "${keyphrase}"` : `Zoeken`}
            </Heading>
          </Box>
        </GridItem>

        <GridItem
          columnEnd={{ initial: '-1', md: '-3', lg: '-4' }}
          columnStart={{ initial: '1', md: '3', lg: '4' }}
        >
          <Box width="100%" paddingBottom={10}>
            <SearchInput
              onChange={(e) => keyphraseChangeFn(e)}
              aria-label="Zoek"
              icon
              isFetching={isFetching}
              defaultValue={urlKeyphrase}
            />
          </Box>
        </GridItem>
      </PageGrid>
      <div>
        {totalItems > 0 && keyphrase && result.isSuccess && facetResult.isSuccess ? (
          <>
            <PageGrid>
              <GridItem
                columnEnd={{ initial: '-1', md: '-3', lg: '-4' }}
                columnStart={{ initial: '1', md: '3', lg: '4' }}
              >
                <Box width="100%">
                  <Box paddingBottom={10} asChild>
                    <fieldset>
                      <VisuallyHidden asChild>
                        <legend>Filter zoekresultaten</legend>
                      </VisuallyHidden>
                      <Stack
                        gap={4}
                        direction={{ initial: 'column', md: 'row' }}
                        alignX={{ md: 'center' }}
                        isFullWidth
                      >
                        {filterTypes.map((facet) => (
                          <FilterItem
                            label={facet.text}
                            key={facet.text}
                            filterKey={facet.name}
                            onChange={() => {
                              // Manually tracking the event for Sitecore
                              const facetIndex = facets.findIndex((f) => f.name === facet.name);
                              const clickedFacet = facets[facetIndex].value;
                              const facetValueIndex = clickedFacet.findIndex(
                                (f) => f.id === facet.id
                              );

                              const trackingObject = {
                                facetId: facet.name,
                                facetValueId: facet.id,
                                facetValue: clickedFacet[facetValueIndex]?.text || '',
                                facetValueIndex,
                                facetIndex,
                                facetTitle: facet.label,
                              };

                              trackSearchResultsFacetClickEvent(props.rfkId, {
                                filters: [trackingObject],
                              });
                            }}
                          />
                        ))}
                      </Stack>
                    </fieldset>
                  </Box>
                  <Box asChild paddingBottom={7}>
                    <Heading size="h5" as="h3" align="center">
                      {totalItems} {totalItems === 1 ? 'resultaat' : 'resultaten'} gevonden voor{' '}
                      <strong>&quot;{keyphrase}&quot;</strong>
                    </Heading>
                  </Box>
                </Box>
              </GridItem>
            </PageGrid>

            <PageGrid>
              <GridItem
                columnEnd={{ initial: '-1', md: '-3' }}
                columnStart={{ initial: '1', md: '3' }}
              >
                <Box width="100%">
                  <Stack gap={4}>
                    {articles.map((a, index) => {
                      const itemName = a.name?.replace(' | Liander', '');
                      const url = new URL(a.url);
                      const link = url.href.replace(url.origin, '');

                      return (
                        <ToneFocus key={index}>
                          <Box padding={12} bg="backgroundLight" borderRadius="md" asChild>
                            <a
                              href={link}
                              onClick={(e) => {
                                // To be ure that the click is tracked, we preventdefault
                                e.preventDefault();

                                // Track clicks on the search results for Sitecore
                                const entity = searchQuery.getRequest().getEntity();
                                trackSearchResultsClickEntityEvent(props.rfkId, entity, {
                                  index,
                                  items: [{ id: a.id, sourceId: a.sourceId }],
                                });

                                // Because we preventDefault, we manually navigate to the url
                                if (link) router.push(link);
                              }}
                              className={styles.searchCard}
                            >
                              <Box position="relative" className={styles.searchCardContent}>
                                <Stack alignX="start">
                                  {a.segment ? (
                                    <Box paddingBottom={6}>
                                      <Tag>{a.segment}</Tag>
                                    </Box>
                                  ) : null}

                                  <Box
                                    paddingBottom={2}
                                    paddingRight={{ initial: 0, md: 12 }}
                                    asChild
                                  >
                                    <Heading size="h5" as="h3" color="onLight">
                                      {a.title || itemName}
                                    </Heading>
                                  </Box>
                                  <Box paddingRight={{ initial: 0, md: 12 }}>
                                    {a.description ? (
                                      <Text size="paragraph">{a.description}</Text>
                                    ) : null}
                                  </Box>

                                  <Box paddingTop={{ initial: 12, md: 6 }}>
                                    <Stack
                                      asChild
                                      alignX={{ initial: 'start', md: 'end' }}
                                      alignY={{ initial: 'end', md: 'end' }}
                                    >
                                      <Box asChild>
                                        <AnimatedArrowComponent
                                          size={{ initial: 'medium', md: 'large' }}
                                        />
                                      </Box>
                                    </Stack>
                                  </Box>
                                </Stack>
                              </Box>
                            </a>
                          </Box>
                        </ToneFocus>
                      );
                    })}
                  </Stack>

                  <div>
                    <Box paddingTop={10} paddingBottom={10}>
                      <Stack alignX="center" direction="row" asChild isFullWidth gap={4}>
                        <Pagination
                          pageCount={totalPages}
                          forcePage={page - 1}
                          onPageChange={(e) => {
                            const selectedPage = e.selected + 1;
                            router.push({
                              pathname: router.pathname,
                              query: {
                                ...router.query,
                                [queryParams.page]: selectedPage,
                              },
                            });
                          }}
                        />
                      </Stack>
                    </Box>
                  </div>
                </Box>
              </GridItem>
            </PageGrid>
          </>
        ) : null}
        {totalItems === 0 && keyphrase && isSuccess && (
          <PageGrid>
            <GridItem
              columnEnd={{ initial: '-1', md: '-4' }}
              columnStart={{ initial: '1', md: '4' }}
            >
              <div>
                <Box asChild width="100%" paddingTop={12} paddingBottom={4}>
                  <Heading size="h5" as="h3" align="start">
                    We hebben geen informatie gevonden voor uw zoekopdracht. Wat kunt u doen?
                  </Heading>
                </Box>
                <RichText>
                  {`<ul>
                    <li>Controleer de spelling van uw zoekopdracht.</li>
                    <li>Probeer een andere zoekopdracht.</li>
                  </ul>`}
                </RichText>
              </div>
            </GridItem>
          </PageGrid>
        )}
      </div>
    </div>
  );
};
const SearchResultsWithInputWidget = widget(
  SearchResultsWithInputComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);

const SearchResults = () => {
  return <SearchResultsWithInputWidget rfkId="rfkid_6" />;
};
export default SearchResults;
