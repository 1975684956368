import { EditPageBanner } from 'src/components/EditPageBanner';
import { useSitecoreContext } from 'src/context/SitecoreContext';
import { InternalOrExternalLink } from 'src/components/InternalOrExternalLink';
import { useMedia } from '@ads-core/hooks';
import { useSession, signIn } from "next-auth/react"
import { mq } from '@ads-core/breakpoints';

export const queryParams = {
  authorMode: 'authorMode',
};

const EditPageCTA = () => {

  const sitecoreContext = useSitecoreContext();
  const sitecoreCmHost = process.env.NEXT_PUBLIC_SITECORE_CM_HOST;
  const isDesktop = useMedia(mq.md);
  const newsArticleTemplateId = "7587d428-3d9d-4d62-b13d-1701349f4377";
  const { data: session } = useSession();


  if (!sitecoreContext.context.editorMode || !isDesktop) {
    return null;
  }
  
  if(!session) {
    return (
      <>
          Niet ingelogd <br />
          <button onClick={() => signIn()}>Inloggen</button>
      </>
    );
  }

  let previewModeHref = `${sitecoreCmHost}/?sc_itemid=${sitecoreContext.route.itemId}&sc_mode=preview&sc_lang=nl-NL&sc_site=liander`;
  let experienceEditorHref = `${sitecoreCmHost}/?sc_itemid=${sitecoreContext.route.itemId}&sc_mode=edit&sc_lang=nl-NL&sc_site=liander`;

  let itemId;

  if (sitecoreContext.route.templateId === newsArticleTemplateId) {
    itemId = sitecoreContext.context.newsArticleItem as string;

    const previewModeUrl = new URL(previewModeHref);
    previewModeUrl.searchParams.set("sc_article", itemId);
    previewModeHref = previewModeUrl.toString();

    experienceEditorHref = "";
  }
  else {
    itemId = sitecoreContext.route.itemId;
  }

  const contentEditorHref = `${sitecoreCmHost}/sitecore/shell/Applications/Content%20Editor.aspx?fo=${itemId}`;

  return (
    <EditPageBanner
      firstLink={
        <InternalOrExternalLink href={contentEditorHref} target="_blank">
          Ga naar Content Editor
        </InternalOrExternalLink>
      }
      secondLink={
        <InternalOrExternalLink href={previewModeHref} target="_blank">
          Ga naar Preview Mode
        </InternalOrExternalLink>
      }
      thirdLink={
        <InternalOrExternalLink href={experienceEditorHref} target="_blank">
          Ga naar Experience Editor
        </InternalOrExternalLink>
      }
    />
  );
};

export default EditPageCTA;
