import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { HomepageHeroSitecore, TopTasksSitecore } from '@ads-core/components';
import type {
  ImageFieldValue,
  Item,
  LinkField,
  TextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreText, SitecoreImage, renderJssLink } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';
import { OutageCheckForm, OutageCheckProps } from 'src/components/OutageCheckForm';
import { CongestionCheckForm } from 'src/components/CongestionCheckForm';
import { CheckTaskProps } from 'src/types';
import { isCongestionCheck } from 'src/utils';

type HomepageHeroApiProps = ComponentProps & {
  fields: {
    image: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    text: TextField;
    tasks: {
      fields: { link: LinkField; title: TextField };
    }[];
    explanation: TextField;
    textLink: LinkField;
    checkTask?: CheckTaskProps;
    outagecheck?: Item & { fields: OutageCheckProps };
  };
};

const HomepageHero = (props: HomepageHeroApiProps) => {
  const { tasks, explanation, image, textLink, text, outagecheck, checkTask } = props.fields;

  // We don't want Next/Link dependencies in our ads-core package, so we do basic mapping to relevant components on here on app level
  const toptasks = tasks
    .map(({ fields: { title, link } }) => ({
      link: renderJssLink({
        ...link,
        value: { ...link.value, text: title.value?.toString() },
      }),
      arrowLink: link.value.href ? (
        <NextLink href={link.value.href} aria-label={link.value.text} />
      ) : null,
    }))
    .filter((t): t is { link: React.ReactElement; arrowLink: React.ReactElement } => !!t.link);

  const isEditor = !!text.editable;

  const variableBlock = () => {
    if (checkTask && isCongestionCheck(checkTask)) {
      return <CongestionCheckForm congestionCheck={checkTask.fields} />;
    }

    // We temporary will support the outagecheck we need to remove this later.
    if ((checkTask && !isCongestionCheck(checkTask)) || outagecheck) {
      return (
        <OutageCheckForm outagecheck={checkTask?.fields ? checkTask.fields : outagecheck?.fields} />
      );
    }
  };

  return (
    <HomepageHeroSitecore
      text={isEditor ? <SitecoreText field={text} editable /> : text.value?.toString()}
      tasks={
        <TopTasksSitecore
          tasks={toptasks}
          variableBlock={variableBlock()}
          variableBlockPosition={isCongestionCheck(checkTask) ? 'start' : 'end'}
        />
      }
      image={<SitecoreImage field={image} editable />}
      textLink={renderJssLink(textLink)}
      explanation={explanation.value ? <SitecoreText field={explanation} editable /> : undefined}
    />
  );
};

export default withDatasourceCheck()<ComponentProps>(HomepageHero);
