import React from 'react';
import { type GetVariants } from 'responsive-recipes';

import { Box } from '../Box';
import { input } from '../InputBase/InputBase.css';
import { Error } from '../InputBase';
import { Text } from '../Text';

import * as styles from './InputGroup.css';

type InputGroupTone = GetVariants<typeof input>;

type InputGroupProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  error?: string;
} & InputGroupTone;

export const InputGroup = (props: InputGroupProps) => {
  const { label, children, error, tone } = props;
  const id = React.useId();
  const errorId = error ? `${id}-error` : undefined;

  return (
    <fieldset role="group" aria-describedby={errorId}>
      <legend className={styles.inputGroupLegend}>
        <Text size="label" color={tone}>
          {label}
        </Text>
      </legend>
      <Box className={styles.inputGroupWrapper}>{children}</Box>
      {error && (
        <Error id={errorId} tone={tone}>
          {error}
        </Error>
      )}
    </fieldset>
  );
};
