import React from 'react';
import clsx from 'clsx';
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon } from '../../icons';
import { Stack } from '../Stack';
import { ToneFocus } from '../ToneFocusProvider';
import { Label, Error } from '../InputBase';
import { Box } from '../Box';
import * as styles from './InputSelect.css';

export type InputSelectOption = {
  displayValue: React.ReactNode;
} & Omit<Select.SelectItemProps, 'textValue'>;

type InputSelectProps = {
  label: string;
  options: InputSelectOption[];
  placeholder?: string;
  ariaLabel?: string;
  content?: Select.SelectContentProps;
  error?: string;
  selectDropDownWidth?: string;
  className?: string;
  handleOnCloseOutFocus?: (e: Event) => void;
} & Select.SelectProps &
  styles.SelectVariants;

export const InputSelect = React.forwardRef<HTMLInputElement, InputSelectProps>(
  (props, ref) => {
    const {
      label,
      error,
      options,
      placeholder,
      ariaLabel,
      tone,
      selectDropDownWidth,
      className,
      handleOnCloseOutFocus,
      ...selectProps
    } = props;
    const id = React.useId();
    const errorId = error ? `${id}-error` : undefined;

    return (
      <Stack ref={ref} gap={2}>
        <Label htmlFor={id} tone={tone}>
          {label}
        </Label>

        <Select.Root {...selectProps}>
          <ToneFocus tone={tone}>
            <Box
              bg="backgroundSubtle"
              borderRadius="md"
              height="55px"
              width="100%"
              padding={3}
              paddingLeft={6}
              paddingRight={6}
              asChild
            >
              <Stack
                direction="row"
                gap={2}
                alignY="center"
                alignX="justify"
                asChild
              >
                <Select.Trigger
                  className={clsx(
                    styles.selectTrigger({ tone }),
                    styles.triggerDropdown,
                    className
                  )}
                  aria-label={ariaLabel}
                  id={id}
                >
                  <Select.Value placeholder={placeholder} />
                  <Select.Icon>
                    <ChevronDownIcon />
                  </Select.Icon>
                </Select.Trigger>
              </Stack>
            </Box>
          </ToneFocus>

          <Box
            style={{
              '--select-content-width': selectDropDownWidth
                ? selectDropDownWidth
                : 'var(--radix-popper-anchor-width)',
            }}
            bg="backgroundSubtle"
            borderRadius="md"
            overflow="hidden"
            asChild
          >
            <Select.Content
              position="popper"
              sideOffset={5}
              align="start"
              side="bottom"
              className={styles.selectContent({ tone })}
              onCloseAutoFocus={handleOnCloseOutFocus}
            >
              <Select.Viewport className={styles.selectViewPort({ tone })}>
                <Select.Group>
                  {options.map((option) => (
                    <Box
                      bg="backgroundSubtle"
                      padding={3}
                      paddingLeft={6}
                      width="100%"
                      height="43px"
                      asChild
                      key={option.value}
                    >
                      <Stack
                        direction="row"
                        alignY="center"
                        alignX="justify"
                        asChild
                      >
                        <Select.Item
                          className={styles.selectItem({ tone })}
                          {...option}
                        >
                          <Select.ItemText>
                            {option.displayValue}
                          </Select.ItemText>
                        </Select.Item>
                      </Stack>
                    </Box>
                  ))}
                </Select.Group>
              </Select.Viewport>
            </Select.Content>
          </Box>
        </Select.Root>

        {error ? (
          <Error id={errorId} tone={tone}>
            {error}
          </Error>
        ) : null}
      </Stack>
    );
  }
);
