import React from 'react';
import {
  GridItem,
  PageGrid,
  Box,
  Heading,
  Stack,
  Loader,
  TextLink,
  Text,
  Columns,
  Divider,
} from '@ads-core/components';
import { IntegrationApiFeaturesOutagesContractsDataLocationInfo as LocationInfo } from '@ads-core/styles/api/data-contracts';
import { OutagesOverviewProps } from 'components/OutagesOverview';
import { setReplaceText } from 'src/utils';
import { capitalizeLocationName } from 'src/utils/format';

import { mq } from '@ads-core/breakpoints';
import useMedia from 'react-use/lib/useMedia';

import * as styles from './OutagesOverview.css';

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';

type Outages = {
  amountOfOutages?: number;
  locations?: LocationInfo[];
};

export const OutagesOverviewView = (props: OutagesOverviewProps['fields']) => {
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const [outages, setOutages] = React.useState<Outages>();
  const isTablet = useMedia(mq.sm);

  const getOutagesOverviewData = async () => {
    setFetchStatus('loading');
    const request = await getOutagesLocationInfo();

    if (!request || !request.locations) {
      setFetchStatus('notFound');
      return;
    }

    if (request.errorMessage) {
      setFetchStatus('error');
      return;
    }

    let amountOfOutages = 0;

    request.locations.forEach((location) => {
      if (location.amountOfOutages) {
        amountOfOutages = amountOfOutages + location.amountOfOutages;
      }
    });

    setOutages({
      amountOfOutages: amountOfOutages,
      locations: request.locations,
    });
    setFetchStatus('done');
  };

  React.useEffect(() => {
    getOutagesOverviewData();
  }, []);

  return (
    <PageGrid>
      <GridItem
        columnStart={{ initial: '1', md: '3' }}
        columnEnd={{ initial: '-1', md: '-3' }}
        asChild
      >
        <Box paddingBottom={{ initial: 2, md: 8 }} asChild>
          <Heading size="h2">
            {setReplaceText(props.title.value, {
              '{amountOfOutages}': outages?.amountOfOutages
                ? outages.amountOfOutages.toString()
                : '0',
            })}
          </Heading>
        </Box>
      </GridItem>
      <GridItem columnStart="1" columnEnd="-1" asChild>
        <Box
          paddingInline={{ initial: 6, md: 16 }}
          paddingTop={{ initial: 8, md: 16 }}
          paddingBottom={{ initial: 4, md: 10 }}
          borderRadius={'brandXl'}
          bg="containerLight"
        >
          {fetchStatus === 'initial' || fetchStatus === 'loading' ? (
            <Box paddingBottom={{ initial: 4, md: 10 }} asChild>
              <Stack alignX="center" alignY="center" isFullHeight isFullWidth>
                <Loader />
              </Stack>
            </Box>
          ) : (
            <Box width="100%">
              <Columns columns={{ initial: 1, sm: 2, lg: 3 }} gap={16}>
                {outages?.locations?.map((outage, index) =>
                  outage.locationName ? (
                    <Box
                      paddingBottom={{ initial: 4, sm: 6 }}
                      overflow="hidden"
                      key={`${index}-${outage.locationName}-${outage.amountOfOutages}`}
                      asChild
                    >
                      <Stack>
                        <Box position="relative" paddingBottom={{ initial: 0, sm: 1 }} asChild>
                          <Stack direction="row" alignX="justify" alignY="end" isFullWidth>
                            <TextLink
                              href={`${
                                props.parentOfOutageLocationWildcardPage.value.href
                              }/${capitalizeLocationName(outage.locationName)}`}
                              className={styles.outageOverviewItemLink}
                            >
                              {capitalizeLocationName(outage.locationName)}
                            </TextLink>
                            <Text size="label">
                              {outage.amountOfOutages === 1
                                ? `(${outage.amountOfOutages} storing)`
                                : `(${outage.amountOfOutages} storingen)`}
                            </Text>
                          </Stack>
                        </Box>
                        {isTablet ? <Divider tone="onLight" /> : null}
                      </Stack>
                    </Box>
                  ) : null
                )}
              </Columns>
            </Box>
          )}
        </Box>
      </GridItem>
    </PageGrid>
  );
};

type LocationInfoResponse = {
  success?: boolean;
  errorMessage?: string;
  locations?: LocationInfo[];
};

// Get de data form the API
const getOutagesLocationInfo = async (): Promise<LocationInfoResponse> => {
  try {
    const uri = `/api/outages/locations?ShowLocationsOfUnresolvedOutagesOnly=true`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error('Rest api call for congestions failed:', e);

    return {
      success: false,
      errorMessage: 'Rest api call for congestions failed',
    };
  }
};
