import { useRef } from 'react';
import * as styles from './ScrollLinkWrapper.css';
interface ScrollLinkWrapperProps {
  children: React.ReactNode;
  anchorId?: string;
}

export const ScrollLinkWrapper = ({
  children,
  anchorId,
}: ScrollLinkWrapperProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  if (!anchorId) return children;
  return (
    <div id={anchorId} ref={anchorRef} className={styles.anchor}>
      {children}
    </div>
  );
};
