import { FileInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { setReplaceText } from '../utils/helpers';
import { ValidationModels } from '../types/global';

const SIZE_UNITS: { [key: string]: string } = {
  '1': 'Bytes',
  '1024': 'KB',
  '1048576': 'MB',
  '1073741824': 'GB',
};

export function isValidFileSize(
  validationModel: ValidationModels[0] | undefined,
  inputValue: File[] | undefined,
  fileInputViewModel: FileInputViewModel
): boolean | string {
  if (!validationModel || !inputValue) {
    return true;
  }

  const fileSize =
    [...inputValue].reduce((acc, file) => acc + file.size, 0) /
    fileInputViewModel.fileSizeUnit;

  return (
    fileSize < fileInputViewModel.maxFileSize ||
    setReplaceText(validationModel.message, {
      '{0}': fileInputViewModel.maxFileSize.toString(),
      '{1}': SIZE_UNITS[fileInputViewModel.fileSizeUnit],
    })
  );
}

export function isValidFileType(
  validationModel: ValidationModels[0] | undefined,
  inputValue: File[] | undefined,
  fileInputViewModel: FileInputViewModel
): boolean | string {
  if (!validationModel || !inputValue) {
    return true;
  }

  return (
    [...inputValue].every((file) => {
      const ext = file.name.split('.').pop() || '';
      return fileInputViewModel.allowedContentTypes.indexOf(ext) !== -1;
    }) ||
    setReplaceText(validationModel.message, {
      '{0}': SIZE_UNITS[fileInputViewModel.allowedContentTypes],
    })
  );
}

export function isValidFileCount(
  validationModel: ValidationModels[0] | undefined,
  inputValue: File[] | undefined,
  fileInputViewModel: FileInputViewModel
): boolean | string {
  if (!validationModel || !inputValue) {
    return true;
  }

  return (
    [...inputValue].length <= fileInputViewModel.maxFileCount ||
    setReplaceText(validationModel.message, {
      '{0}': fileInputViewModel.maxFileCount.toString(),
      '{1}': fileInputViewModel.title,
    })
  );
}
