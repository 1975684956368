import React from 'react';
import { forceString } from 'src/utils';
import {
  ListHeroView,
  Stack,
  TextLink,
  Text,
  Box,
  AnimatedArrowComponent,
  Grid,
  GridItem,
  ButtonUnset,
  Dialog,
  StackItem,
} from '@ads-core/components';
import {
  ChecklistIcon,
  ChevronRightIcon,
  NoElectricalConnectionIcon,
  NoGasConnectionIcon,
  PhoneAndroidIcon,
} from '@ads-core/icons';
import { getDate, getMonth, getYear } from 'date-fns';
import { SitecoreImage } from '@alliander-fe/jss-utils';
import { OutagesOnLocationProps } from 'components/OutagesOnLocation';
import { useRouter } from 'next/router';
import { setReplaceText } from '@alliander-fe/validation';
import { useDialogContext, useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';
import { getOutageData } from '../OutageHero';
import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';

import * as styles from './LocationOutages.css';

type OutageProps = {
  referenceNumber: number;
  estimatedEndTime: string;
  reportTime: string;
  endDate: string;
  endTime: string;
  duration: string;
  status: string;
  cause: string;
  energyType: string;
  affectedCustomers: string;
  affectedPostalCodes: string;
  affectedStreets: string;
  rings?: number[][][];
};

type OutagesProps = {
  location: string;
  amountOfOutages?: number;
  outages?: OutageProps[];
};

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';

export const LocationOutages = ({
  outageDetailsModalText,
  outageDetailsModalLinkOne,
  outageDetailsModalLinkTwo,
  ...props
}: OutagesOnLocationProps['fields']) => {
  const { isOpen, openDialog } = useDialogContext();
  const router = useRouter();
  const [outages, setOutages] = React.useState<OutagesProps>();
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const [outageDialogData, setOutageDialogData] = React.useState<OutageDialogContentProps>();
  const resolvedOutages = props?.onlyShowRecentlyResolvedOutages?.value;

  const getOutages = async (location?: string) => {
    if (!location) return;

    setFetchStatus('loading');
    const request = await getOutagesOnLocation(location, resolvedOutages);

    if (request.errorMessage && request.success === false) {
      setFetchStatus('error');
      return;
    }

    setOutages({
      location: capitalizeLocationName(location),
      amountOfOutages: request.outages?.length,
      outages: request.outages,
    });

    setFetchStatus('done');
  };

  const handleOpenDialog = async (props: OutageProps) => {
    if (!resolvedOutages) {
      const outageModalData = await getOutageData(props.referenceNumber.toString());

      if (outageModalData && outageModalData.referenceNumber) {
        setOutageDialogData({
          title: outages?.location ? outages.location : '',
          statusDescription: '',
          postalCode: '',
          city: outages?.location ? outages.location : '',
          ...outageModalData,
          affectedStreets: setReplaceText(props.affectedStreets, { ';': ', ' }),
          affectedPostalCodes: setReplaceText(props.affectedPostalCodes, { ';': ', ' }),
          outageDetailsText: outageDetailsModalText,
          outageDetailsLinkOne: outageDetailsModalLinkOne,
          outageDetailsLinkTwo: outageDetailsModalLinkTwo,
        });

        openDialog();
        return;
      }
    }

    setOutageDialogData({
      title: outages?.location ? outages.location : '',
      city: outages?.location ? outages.location : '',
      ...props,
      affectedStreets: setReplaceText(props.affectedStreets, { ';': ', ' }),
      affectedPostalCodes: setReplaceText(props.affectedPostalCodes, { ';': ', ' }),
      outageDetailsText: outageDetailsModalText,
      outageDetailsLinkOne: outageDetailsModalLinkOne,
      outageDetailsLinkTwo: outageDetailsModalLinkTwo,
    });

    openDialog();
  };

  // Create the replace text for the outages based on the maintenance and outages.
  const outagesTitle = (
    outages: number | undefined,
    resolvedTitle: string | undefined,
    location: string | undefined
  ): string => {
    if (resolvedTitle) return setReplaceText(resolvedTitle, {'{location}': location ? location : ''});

    switch (outages) {
      case 1: 
        return `Er is ${outages} stroom- of gasonderbreking in ${location}`
      case 0:
        return `Er zijn geen stroom- of gasonderbrekingen in ${location}`
      default:
        return `Er zijn ${outages} stroom- en/of gasonderbrekingen in ${location}`
    }
  };

  React.useEffect(() => {
    const path = router.query.path;
    const locationQuery = forceString(router.query.plaats);
    let locationPath: string | undefined;

    if (typeof path === 'object') {
      locationPath = path[path.length - 1];
    }

    getOutages(locationQuery.length ? locationQuery : locationPath);
  }, [router.query.path]);

  React.useEffect(() => {
    if (!isOpen) {
      setOutageDialogData(undefined);
    }
  }, [isOpen]);

  return (
    <>
      {outageDialogData ? (
        <Dialog>
          <OutageDialogContent {...outageDialogData} resolvedOutages={resolvedOutages} />
        </Dialog>
      ) : null}
      <ListHeroView
        image={<SitecoreImage field={props.image} editable />}
        variant={resolvedOutages ? 'contained' : 'breakout'}
        text={
          fetchStatus !== 'initial' && fetchStatus !== 'loading'
          ? outagesTitle(
              outages?.amountOfOutages,
              resolvedOutages ? props?.title?.value : undefined,
              outages?.location
            )
          : undefined
        }
        list={
          fetchStatus !== 'initial' && fetchStatus !== 'loading' && outages?.outages ? (
            <Box width="100%" overflowY="hidden" asChild>
              <Stack
                direction={resolvedOutages ? { initial: 'row', md: 'column' } : 'column'}
                gap={4}
                isFullWidth
              >
                {outages.outages.slice(0, resolvedOutages ? 2 : outages.outages.length).map((outage, index) => (
                  <Box
                    width={resolvedOutages ? { initial: '90%', sm: '50%', md: '100%' } : '100%'}
                    key={index}
                    asChild
                  >
                    <StackItem shrink={false}>
                      <OutageBlock
                        {...outage}
                        resolvedOutages={resolvedOutages}
                        handleToggleDialog={(props) => handleOpenDialog(props)}
                      />
                    </StackItem>
                  </Box>
                ))}
              </Stack>
            </Box>
          ) : (
            <Box />
          )
        }
        explanation={
          fetchStatus !== 'initial' && fetchStatus !== 'loading' ? (
            <Stack gap={2} alignY="end">
              <ExplanationLinks
                resolvedOutages={resolvedOutages}
                hasOutages={typeof outages?.amountOfOutages === 'number' && outages?.amountOfOutages > 0}
                outageData={props}
              />
            </Stack>
          ) : undefined
        }
      />
    </>
  );
};

const ExplanationLinks = ({
  resolvedOutages,
  outageData,
  hasOutages
}: {
  resolvedOutages?: boolean,
  hasOutages: boolean,
  outageData: OutagesOnLocationProps['fields'],
}) => {
  const isDesktop = useMedia(mq.md);

  if(!hasOutages) {
    return (
      <>
        {outageData.noOutagesLinkOne ? (
          <TextLink
            href={outageData.noOutagesLinkOne.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData.noOutagesLinkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.noOutagesLinkTwo ? (
          <TextLink
            href={outageData.noOutagesLinkTwo.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData.noOutagesLinkTwo.value?.text}
          </TextLink>
        ) : null}
      </>
    )
  }

  if(resolvedOutages && isDesktop) {
    return (
      <>
        {outageData.linkOne ? (
          <TextLink
            href={outageData?.linkOne?.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.linkTwo ? (
          <TextLink
            href={outageData?.linkTwo?.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkTwo?.value?.text}
          </TextLink>
        ) : null}
      </>
    );
  }

  return (
    <>
      {outageData.linkOne ? (
        <TextLink
          href={outageData?.linkOne?.value?.href}
          beforeIcon={
            !resolvedOutages ? (
              <PhoneAndroidIcon size="sm" />
            ) : (
              <ChevronRightIcon size="sm" />
            )
          }
        >
          {outageData?.linkOne?.value?.text}
        </TextLink>
      ) : null}
      {outageData.linkTwo ? (
        <TextLink
          href={outageData?.linkTwo?.value?.href}
          beforeIcon={
            !resolvedOutages ? (
              <ChecklistIcon size="sm" />
            ) : (
              <ChevronRightIcon size="sm" />
            )
          }
        >
          {outageData?.linkTwo?.value?.text}
        </TextLink>
      ) : null}
    </>
  )
} 

type OutageBlockProps = {
  handleToggleDialog: (data: OutageProps) => void;
  resolvedOutages?: boolean;
} & OutageProps;

const OutageBlock = ({ handleToggleDialog, resolvedOutages, ...props }: OutageBlockProps) => {
  const isDesktop = useMedia(mq.md);
  const [isHovered, setIsHovered] = React.useState(false);
  const isMaintenance = props.cause === 'Geplande werkzaamheden'

  return (
    <Box
      paddingInline={10}
      paddingBlock={8}
      width="100%"
      bg="containerLight"
      borderRadius="brandLg"
      position="relative"
    >
      {isDesktop ? (
        <Grid gap={{ initial: 2, sm: 4, lg: 6 }} asChild>
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <GridItem columnStart="1" columnEnd="4" asChild>
              <Stack gap={2}>
                <Text size="label">
                  {isMaintenance ? "Gepland onderhoud" : "Storing"}
                </Text>
                <Stack direction="row" gap={2}>
                  {props.energyType === 'Elektriciteit' ? (
                    <NoElectricalConnectionIcon size="xs" />
                  ) : (
                    <NoGasConnectionIcon size="xs" />
                  )}
                  <Text>{props.energyType === 'Elektriciteit' ? 'Stroomstoring' : 'Gasstoring'}</Text>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem columnStart="4" columnEnd="7" asChild>
              {resolvedOutages ? (
                <Stack gap={2}>
                  <Text size="label">Datum</Text>
                  <Text>{setOutageEndDate(props.endDate)}</Text>
                </Stack>
              ) : (
                <Stack gap={2}>
                  <Text size="label">Duratie</Text>
                  <Text>{props.duration}</Text>
                </Stack>
              )}
            </GridItem>

            <GridItem columnStart="7" columnEnd="12" asChild>
              {resolvedOutages ? (
                <Stack gap={2}>
                  <Text size="label">Oorzaak</Text>
                  <Text>{props.cause}</Text>
                </Stack>
              ) : (
                <Stack gap={2}>
                  <Text size="label">Straten</Text>
                  <Text>{setReplaceText(props.affectedStreets, { ';': ', ' })}</Text>
                </Stack>
              )}
            </GridItem>

            <GridItem columnStart="12" columnEnd="-1">
              <Stack alignX="end" alignY="center" isFullHeight isFullWidth>
                <ButtonUnset
                  className={styles.ListItemLink}
                  onClick={() => handleToggleDialog(props)}
                >
                  <AnimatedArrowComponent active={isHovered} size={'large'} />
                </ButtonUnset>
              </Stack>
            </GridItem>
          </div>
        </Grid>
      ) : (
        <Stack gap={4}>
          <Stack gap={2}>
            <Text size="label">
              {isMaintenance ? "Gepland onderhoud" : "Storing"}
            </Text>
            <Stack direction="row" gap={2}>
              {props.energyType === 'Elektriciteit' ? (
                <NoElectricalConnectionIcon size="xs" />
              ) : (
                <NoGasConnectionIcon size="xs" />
              )}
              <Text>{props.energyType}</Text>
            </Stack>
          </Stack>

          {resolvedOutages ? (
            <Stack gap={2}>
              <Text size="label">Datum</Text>
              <Text>{setOutageEndDate(props.endDate)}</Text>
            </Stack>
          ) : (
            <Stack gap={2}>
              <Text size="label">Duratie</Text>
              <Text>{props.duration}</Text>
            </Stack>
          )}

          {resolvedOutages ? (
            <Stack gap={2}>
              <Text size="label">Oorzaak</Text>
              <Text>{props.cause}</Text>
            </Stack>
          ) : (
            <Stack gap={2}>
              <Text size="label">Straten</Text>
              <Text>{setReplaceText(props.affectedStreets, { ';': ', ' })}</Text>
            </Stack>
          )}

          <TextLink afterIcon={<ChevronRightIcon />} asChild>
            <button className={styles.ListItemLink} onClick={() => handleToggleDialog(props)}>
              Bekijk alle details
            </button>
          </TextLink>
        </Stack>
      )}
    </Box>
  );
};

// Because we only have data of this and last year we can set the year based on the month.
export const setOutageEndDate = (date: string): string => {
  const currentDate = new Date();
  const currentDay = getDate(currentDate);
  const currentMonth = getMonth(currentDate);
  const currentYear = getYear(currentDate);

  const inputDate = new Date(date);
  const inputDay = getDate(inputDate);
  const inputMonth = getMonth(inputDate);

  if (currentMonth >= inputMonth && currentDay >= inputDay) {
    return `${date} ${currentYear}`
  }

  return `${date} ${currentYear - 1}`
}

const capitalizeLocationName = (location: string): string => {
  const splitOnSpace = location.split(' ');
  let capitalizedLocationName = '';

  splitOnSpace.forEach((word) => {
    capitalizedLocationName = capitalizedLocationName.length
      ? `${capitalizedLocationName} ${word.charAt(0).toUpperCase() + word.slice(1)}`
      : `${word.charAt(0).toUpperCase() + word.slice(1)}`;
  });

  return capitalizedLocationName;
};

type OutagesOnLocationRequestProps = {
  outages?: OutageProps[];
  success?: boolean;
  errorMessage?: string;
};

// Get de data form the API
const getOutagesOnLocation = async (
  city: string,
  resolved?: boolean,
  amount?: number
): Promise<OutagesOnLocationRequestProps> => {
  try {
    // https://api.tst.dxp.alliander.com/api/outages/on-location/amsterdam?resolved=false&amount=2
    let additionalRequest = '';

    if (resolved) {
      additionalRequest = `?resolved=${resolved}`;
    }

    if (amount) {
      additionalRequest = `?amount=${amount}`;
    }

    if (resolved && amount) {
      additionalRequest = `?resolved=${resolved}&amount=${amount}`;
    }

    const uri = `/api/outages/on-location/${city}${additionalRequest}`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error('Rest api call for congestions failed:', e);

    return {
      success: false,
      errorMessage: 'Rest api call for congestions failed',
    };
  }
};
 