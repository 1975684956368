import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { ComponentProps } from 'src/jss/lib/component-props';

import { forceArray, baseQueryParams, slugify, createFilterMapper, formatDate } from 'src/utils';
import { Filter } from 'src/types';

import {
  Dropdown,
  GridItem,
  Heading,
  InfoSectionComponent,
  PageGrid,
  Pagination,
  SimpleHero,
  Stack,
  StackItem,
} from '@ads-core/components';

import { useRouter } from 'next/router';
import NextLink from 'next/link';
import NextImage from 'next/image';

type NewsOverviewProps = ComponentProps & {
  fields: {
    title: { value: string };
    subtitle: { value: string };
    tagFilter: Filter[];
    regionsFilter: Filter[];
    segmentFilter: Filter[];
  };

  params?: {
    anchor?: string;
  };
};

type NewsArticle = {
  publicationDate: string;
  segment: string;
  tag: string;
  regions: string[];
  title: string;
  image: {
    src: string;
    alt: string;
    width: string;
    height: string;
  } | null;
  itemPath: string;
};

type NewsArticleJson = {
  totalAmount: number;
  amountOfPages: number;
  newsArticles: NewsArticle[];
};

export const queryParams = {
  ...baseQueryParams,
  region: 'regio',
  segment: 'segment',
  tag: 'tag',
};

const NewsOverview = (props: NewsOverviewProps) => {
  const router = useRouter();
  const currentPath = forceArray(router.query.path).join('/');

  const [loading, setLoading] = React.useState<'initial' | 'loading' | 'done'>('initial');

  const [newsData, setNewsData] = React.useState<{
    pageCount: number;
    articles: NewsArticle[];
    totalAmount: number;
  } | null>();

  const { tagFilter, regionsFilter, segmentFilter } = props.fields;

  // Get data from the query params
  const page = router.query[queryParams.page];
  const currentPage = forceArray(page).length > 0 ? Number(forceArray(page)[0]) : 1;

  const region = forceArray(router.query[queryParams.region])[0] || '';
  const segment = forceArray(router.query[queryParams.segment])[0] || '';
  const tag = forceArray(router.query[queryParams.tag])[0] || '';

  // Create mappers for pretty a pretty url
  const regionMapper = createFilterMapper(regionsFilter, 'id');
  const tagMapper = createFilterMapper(tagFilter, 'displayName');
  const segmentMapper = createFilterMapper(segmentFilter, 'id');

  // Filter values
  const regionFilterValue = regionMapper[region] || '';
  const tagFilterValue = tagMapper[tag] || '';
  const segmentFilterValue = segmentMapper[segment] || '';

  // Get page count from the data
  const pageCount = newsData?.pageCount || 1;

  React.useEffect(() => {
    // TODO: We can use react-query to handle caching/deduping etc in the future
    async function getData() {
      try {
        setLoading('loading');
        // Create an api url with the query params
        const url = new URL('/api/get-news-articles', window.location.origin);
        url.searchParams.set('pageNumber', `${Math.max(currentPage - 1, 0)}`);
        url.searchParams.set('region', regionFilterValue);
        url.searchParams.set('tag', tagFilterValue);
        url.searchParams.set('segment', segmentFilterValue);

        const data = await fetch(url.href);

        const json: NewsArticleJson = await data.json();

        setNewsData({
          pageCount: json.amountOfPages,
          articles: json.newsArticles,
          totalAmount: json.totalAmount,
        });
      } catch (error) {
        console.error('Error while fetching news articles', error);
        throw Error('Er is iets misgegaan met het ophalen van de nieuwsartikelen');
      } finally {
        setLoading('done');
      }
    }

    getData();
  }, [currentPage, regionFilterValue, tagFilterValue, segmentFilterValue]);

  const newsArticles = (newsData?.articles || []).map((article) => ({
    publicationDate: formatDate(article.publicationDate),
    tag: article.tag,
    regions: article.regions,
    title: article.title,
    infoImage: article.image ? (
      <NextImage
        src={article.image.src}
        alt={article.image.alt}
        width={Number(article.image.width)}
        height={Number(article.image.height)}
      />
    ) : undefined,
    link: <NextLink href={`${currentPath}/${article.itemPath}`} />,
  }));

  if (!newsData) {
    return null;
  }

  return (
    <>
      <SimpleHero title={props.fields.title.value} text={props.fields.subtitle.value} />
      <PageGrid as="section">
        <GridItem columnEnd="-1" columnStart="1">
          <Stack
            direction={{ initial: 'column', md: 'row' }}
            isFullWidth
            gap={{ initial: 4, md: 10 }}
          >
            <StackItem grow>
              <Dropdown
                placeholder="Kies een segment"
                label="Filter op segment"
                name="segment"
                items={[
                  { label: 'Toon alle', value: '' },
                  ...props.fields.segmentFilter.map((item) => {
                    return {
                      label: item.displayName,
                      value: item.id,
                    };
                  }),
                ]}
                value={segmentFilterValue}
                onValueChange={(value) => {
                  const selectedItem = props.fields.segmentFilter.find((item) => item.id === value);

                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      [queryParams.segment]: selectedItem ? slugify(selectedItem.displayName) : '',
                    },
                  });
                }}
              />
            </StackItem>
            <StackItem grow>
              <Dropdown
                placeholder="Kies een regio"
                label="Filter op regio"
                name="regio"
                items={[
                  { label: 'Toon alle', value: '' },
                  ...props.fields.regionsFilter.map((region) => {
                    return {
                      label: region.displayName,
                      value: region.id,
                    };
                  }),
                ]}
                value={regionFilterValue}
                onValueChange={(value) => {
                  const selectedItem = props.fields.regionsFilter.find((item) => item.id === value);

                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      [queryParams.region]: selectedItem ? slugify(selectedItem.displayName) : '',
                    },
                  });
                }}
              />
            </StackItem>
            <StackItem grow>
              <Dropdown
                placeholder="Kies een tag"
                label="Filter op tag"
                name="tag"
                items={[
                  { label: 'Toon alle', value: '' },
                  ...props.fields.tagFilter.map((tag) => {
                    return {
                      label: tag.displayName,
                      value: tag.displayName,
                    };
                  }),
                ]}
                value={tagFilterValue}
                onValueChange={(value) => {
                  const selectedItem = props.fields.tagFilter.find(
                    (item) => item.displayName === value
                  );

                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      [queryParams.tag]: selectedItem ? slugify(selectedItem.displayName) : '',
                    },
                  });
                }}
              />
            </StackItem>
          </Stack>
        </GridItem>
      </PageGrid>
      {loading === 'loading' && (
        <PageGrid>
          <GridItem columnStart="1" columnEnd="-1">
            <Heading size="h5" as="h3">
              Bezig met laden..
            </Heading>
          </GridItem>
        </PageGrid>
      )}

      {newsArticles.length > 0 ? (
        <InfoSectionComponent articles={newsArticles} />
      ) : (
        <PageGrid>
          <GridItem columnStart="1" columnEnd="-1">
            <Heading size="h5" as="h3">
              Geen resultaten
            </Heading>
          </GridItem>
        </PageGrid>
      )}
      <Pagination
        forcePage={pageCount < 2 ? undefined : currentPage - 1}
        pageCount={pageCount < 2 ? 0 : pageCount}
        onPageChange={(e) => {
          const selectedPage = e.selected + 1;
          router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              [queryParams.page]: selectedPage,
            },
          });
        }}
      />
    </>
  );
};

export default withDatasourceCheck()<ComponentProps>(NewsOverview);
