import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/cpBCwW2AKOMOQctXvl+nBZ7BssJEtK3Ta2a7uNk2LffUk1KSDOsEATxHB+coYcaj6K9b9w2dsDWFN9+62q7u5e/4e/qt/xLSaMf890JH07/cx1S3pC2MT1XHekGxObpZ3rnnS3BXSruR6UfaISNyl6o+itsn835el9DHN9p+TfK/sPij4q+r1S5/dKPnsl/w/KPh+VfT4p53pQ8jwo+lHRT4r+WdHPSv4Xxf+q+H9R/L8q+qOyz4300fPvXH9iHXbOjHP9mXRou12zm+tglMDAhG2XW7c1woDKIwP7I1cr1zgtiteiBOXgEJUbBozaZrVebwSywKyFFTQhCQPD5lfgfSMMnUI/aLgB87ZuNqutNAzTipDSRhjG72VcRVmSe62+zFxcv3VWZsXQbfzWrOUBmbqhH+PQCwNjN/gxjNLA3I3j6AZxr4DBG/uhz7Y6aCU5auc4aSsm+JZNBFkrpm9NK4ysFeF3PbeHy6k9D4erMGoMwpefrdJIhEft4t202/KkHfRZKQ0aBQcEJThqLzy0yunRKVVGrxkmFofYWxQGZtFgSj0IQ5ouXtgZuYJZpH1GFP0JW+VyI7O4tIZ+hIFYNKdbBYY/E3+c77v2TxsXFcKisovK1AbfLCYvB3WMMdA3a2uHCGV3cDZGeFmDWFsbLMeIdQqNsgQa2u5lCWDtEcKUFpkiKmte3EMdvOFUfE3P3qSyt0kmxhRes6pjiBimb0gDhnIS4wNiA29E0ahP7Q+X4VqZ6ZcXl2/JUHAs3jPqY3zKvr28H3rl9t7PfUpMIHU3d7oJca++UvDDj4ufmT5qsGhTBT5osBwUtvGoxdAmC/ysxdC6G140Jq4aEzxedH3fdIMwcFdrd303SiC5q41dj60YDHCaMGw3ZFs9TdwJ8Xl6wmLmNoVHypN7ykTqYobl1z9htmz+jtXrh/CgpubNH0L0hUx5gs+TiqWkqIlBfijqYIX41L4wj0W9C/Ng1Lgwj0Zk2jwaYWjzaMScy6MRZS7fl7jy+Q4EVsgzI6ZingPhlPJoxFKT7/vANcudD1y0XD5y1XKZEPrnfLztH9rq3fFru6iW5337aVFd6J19dxnOe/Hy4Kn91xYQckHCw7O8y8RrsajEGdTipcczfOGpPJZcb8WH/cSuojXx5J7fFmcKrg5K181hyZWI4negUF3JlSiCPIHArmJIdLHEhkslV8Yoz7V9cf3vfyHuM7j5DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VXTW+jMBC991dYlVYCaR1B0iQtvexhj3vcw95WDnaCW2Ij47RNV/nva4wBG0xCIvUSBTwfb2bezJjZZ3ZcEBKBf3cAYFoWOTomgHFGntWLd4plloD58lv1lBG6y2QC4qj4qJ43XGAioOSFEik+AEZlRjCQArGyQIIwWUkVvKSScpYAtCl5fpDaslZaRsru6W5mMMzIG2EaiGgc9QQ4xvo8J1vfcXooJd8T8VMD+UUZ0dIGZ8pzLhLwhkQA4d+4pAsWL+I0vGAi2ZAtF+Q7OCeDtpKI2hlKX3eCHxie6JDvC8SO10BeX7DgQTwQuQJwz11VJONB66ecSVXqBNzfW5RpODLCGYEwPZSGAWMccVhgKBPpfxXDFIB9Uv/NkSSBMvUdQPUbViKfkDJMPpSHPoGs0L8Ouk3Qi8jhBOixBtw5E0Sp0jftbI/EjjK4yXn6CkuJhKzQVaA79TMVG+v66/JQR5yiPA2aQHzB/gnaOP2Za6OP28zBuRtL24B2UNf1Xdy0xFUmnE6Y9yriYa6rvliHbUieE2W4ies32k0DhG1Ai9tjWdw8Dx5uALq8QWd1hv8tX511sBpv9I7RDzWlmz3nELxmtLXYPHk43/9RR+GFy+H11HB63U0YHpDHydN6StS14S8POnZn0DphMoNpRnMcqDkctvvJPqi2S6jR+6ZaZJl7THJUylrNCro3zVrxJ326VSmBJf0kCt2qDtwdOP7hZlK1nLsTsXn2p6M2VacBqNd0kMxYJ/NFdT3dHmFbrlT9EuGM5m1OtCeU0x2DVJJ9act5G2iureuI3w3gVRT5CjVxNbW5RDcMis0NUzqdppNpnR97gikCZSqIukUihkGwV/xpu2muer1m1sy+81pJ1lSrUnHqhJzFeUF2ZYQKhDFlO+ib9C9hX8nibn+LAghiBXqg4UDqdfnEumhBL9eHC98Da2lgjXHfEGu1biz07hTuLccJb23iMhoPNSpboDdHEsZlkGypaKZBaCz4JghU06+GMGKxG0CtAcpydWVuLKCD5M/X2rdrfLE+cdivw+UcDEnU4TS3EV0+qL7lLFrZA+BcQsZIOs3cyKj2tZM1XAdBP7nMWD02XGpUmjeWDjY63femlZHo2ZuvCZ8Xlgfi9TDlon+6O/0HVeqRgn8PAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';