export function formatDate(timestamp: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Europe/Amsterdam',
  };

  const date = new Date(timestamp);
  return date.toLocaleDateString('nl-NL', options);
}

// Create a regex for the replacements and replace the values in the string.
export const setReplaceText = (string: string, replacements: { [key: string]: string }): string => {
  const pattern = new RegExp(
    Object.keys(replacements)
      .map((key) => `\\${key}`)
      .join('|'),
    'g'
  );
  return string.replace(pattern, (match: string) => replacements[match]);
};

// Transforms location names like: 'T HARDE or 't harde to 't Harde and ST.-JACOBIPAROCHIE to St.-Jacobiparochie.
export const capitalizeLocationName = (location: string): string => {
  const splitOnSpace = location.split(' ');
  let capitalizedLocationName = '';

  splitOnSpace.forEach((word) => {
    capitalizedLocationName = capitalizedLocationName.length
      ? `${capitalizedLocationName} ${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}`
      : `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}`;
  });

  if (/[-]/.test(capitalizedLocationName)) {
    const splitOnDash = capitalizedLocationName.split('-');
    let capitalizedLocationNameWithDashes = '';

    splitOnDash.forEach((word) => {
      capitalizedLocationNameWithDashes = capitalizedLocationNameWithDashes.length
        ? `${capitalizedLocationNameWithDashes}-${
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          }`
        : `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}`;
    });

    return capitalizedLocationNameWithDashes;
  }

  return capitalizedLocationName;
};
