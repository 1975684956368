import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/cpBCwW2AKOMOQctXvl+nBZ7BssJEtK3Ta2a7uNk2LffUk1KSDOsEATxHB+coYcaj6K9b9w2dsDWFN9+62q7u5e/4e/qt/xLSaMf890JH07/cx1S3pC2MT1XHekGxObpZ3rnnS3BXSruR6UfaISNyl6o+itsn835el9DHN9p+TfK/sPij4q+r1S5/dKPnsl/w/KPh+VfT4p53pQ8jwo+lHRT4r+WdHPSv4Xxf+q+H9R/L8q+qOyz4300fPvXH9iHXbOjHP9mXRou12zm+tglMDAhG2XW7c1woDKIwP7I1cr1zgtiteiBOXgEJUbBozaZrVebwSywKyFFTQhCQPD5lfgfSMMnUI/aLgB87ZuNqutNAzTipDSRhjG72VcRVmSe62+zFxcv3VWZsXQbfzWrOUBmbqhH+PQCwNjN/gxjNLA3I3j6AZxr4DBG/uhz7Y6aCU5auc4aSsm+JZNBFkrpm9NK4ysFeF3PbeHy6k9D4erMGoMwpefrdJIhEft4t202/KkHfRZKQ0aBQcEJThqLzy0yunRKVVGrxkmFofYWxQGZtFgSj0IQ5ouXtgZuYJZpH1GFP0JW+VyI7O4tIZ+hIFYNKdbBYY/E3+c77v2TxsXFcKisovK1AbfLCYvB3WMMdA3a2uHCGV3cDZGeFmDWFsbLMeIdQqNsgQa2u5lCWDtEcKUFpkiKmte3EMdvOFUfE3P3qSyt0kmxhRes6pjiBimb0gDhnIS4wNiA29E0ahP7Q+X4VqZ6ZcXl2/JUHAs3jPqY3zKvr28H3rl9t7PfUpMIHU3d7oJca++UvDDj4ufmT5qsGhTBT5osBwUtvGoxdAmC/ysxdC6G140Jq4aEzxedH3fdIMwcFdrd303SiC5q41dj60YDHCaMGw3ZFs9TdwJ8Xl6wmLmNoVHypN7ykTqYobl1z9htmz+jtXrh/CgpubNH0L0hUx5gs+TiqWkqIlBfijqYIX41L4wj0W9C/Ng1Lgwj0Zk2jwaYWjzaMScy6MRZS7fl7jy+Q4EVsgzI6ZingPhlPJoxFKT7/vANcudD1y0XD5y1XKZEPrnfLztH9rq3fFru6iW5337aVFd6J19dxnOe/Hy4Kn91xYQckHCw7O8y8RrsajEGdTipcczfOGpPJZcb8WH/cSuojXx5J7fFmcKrg5K181hyZWI4negUF3JlSiCPIHArmJIdLHEhkslV8Yoz7V9cf3vfyHuM7j5DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA52UUU+DMBDH3/0UjYnJfOgClG3aveybmFKO0chabMtEzb67FBgp21CUhATu7v+767XX3St8ZJodwKC3tdhXqwB93SGUaXVoPxAqILMU4TAIHraN4dS8Vo18QWc/3e0uYeFtWDAB8pLcgEVt7IXEL2sqzw0WuV3YnEUuO8Kq9SaMv+61qmSKuSqUpujI9ALjl9AIIkMSJo9bT0OlzTHPRZEuosd5ADYFIDMBz1OAeCZgMwbk6gj6H4tfz9PsR5p/NCybAsxtGEwB5jaMjwF/aNho8ZtWcxASv4vU5hSRdVzWXsBTd4gLqHHCjDAUrdzxHfzPV35ClqR5/CB2FRStfH/yi593Uwm1xSlwpZkVSlIklQQ3MaUyorNoKBrfEUbaLjsrCooqacA6Ca+0cb0plZAW9Die0gQypaHVcdUEyGY47+/HuVhiVFHZtgKryn56vUHuO3qe9xzEPvfm/3qjrGbSlEw36VzAJxYyhbqlDeWlQ6sakmfPPDtx9t0BUsGQ4RpAIiZTtPC2OYzjoKy7s9YT4v4qGtjux0nOhUdOMtx2P+I366cLOvR0H3juxDln1MO/Af44OP4wBgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var TopTaskLink = 'q6igu5c';
export var TopTasks = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{color:{purple:{initial:'q6igu55'},blue:{initial:'q6igu56'}}},compoundVariants:[],defaultVariants:{color:'purple'},baseClassName:'q6igu54'});
export var arrowHover = 'q6igu50';
export var arrowHoverOut = 'q6igu51';
export var arrowWhiteHover = 'q6igu52';
export var arrowWhiteHoverOut = 'q6igu53';
export var task = 'q6igu5b';
export var taskBlock = 'q6igu5f';
export var tasksWrapper = 'q6igu5d';
export var variableBlockMobile = 'q6igu5e';
export var variableBlockTask = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{items:{'2':{initial:'q6igu58'},'3':{initial:'q6igu59'},'4':{initial:'q6igu5a'}}},compoundVariants:[],defaultVariants:{},baseClassName:'q6igu57'});