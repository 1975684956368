import { getDate, getMonth, getYear } from "date-fns";

// Because we only have data of this and last year we can set the year based on the month.
export const setOutageEndDate = (date: string): string => {
  const currentDate = new Date();
  const currentDay = getDate(currentDate);
  const currentMonth = getMonth(currentDate);
  const currentYear = getYear(currentDate);

  const inputDate = new Date(date);
  const inputDay = getDate(inputDate);
  const inputMonth = getMonth(inputDate);

  if (currentMonth >= inputMonth && currentDay >= inputDay) {
    return `${date} ${currentYear}`
  }

  return `${date} ${currentYear - 1}`
}
