import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { OutageHeroComponent } from 'src/components/OutageHero';

export type OutageTasksProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    title: { value: string };
    color: { value: string };
    link: { value: ButtonProps };
  };
};

type FollowUpStepProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    moreDetailsText: { value: string };
    moreDetailsTitle: { value: string };
    text: { value: string };
    title: { value: string };
    type: { value: string };
    moreDetailsButton: { value: ButtonProps };
    moreDetailsTag: { value: string };
    tag: { value: string };
  };
};

type FollowUpStepFieldProps = {
  followUpSteps: FollowUpStepProps[];
  tag: { value: string };
  title: { value: string };
};

export type OutageFollowUpStepsProps = {
  displayName: string;
  fields: FollowUpStepFieldProps;
  id: string;
  name: string;
  url: string;
};

export type OutageHeroProps = {
  fields?: {
    redirectPageNoOutageFound?: { value?: ButtonProps };
    outageText?: { value?: string };
    noOutageTasks: OutageTasksProps[];
    noOutageText?: { value?: string };
    outageTasks: OutageTasksProps[];
    noOutageLink?: { value?: ButtonProps };
    outagePhaseOneLabel?: { value?: string };
    outagePhaseOneText?: { value?: string };
    outagePhaseTwoLabel?: { value?: string };
    outagePhaseTwoText?: { value?: string };
    outagePhaseThreeLabel?: { value?: string };
    outagePhaseThreeText?: { value?: string };
    outageDetailsModalText?: { value?: string };
    outageDetailsModalLinkOne?: { value?: ButtonProps };
    outageDetailsModalLinkTwo?: { value?: ButtonProps };
    maintenancePhaseOneLabel?: { value?: string };
    maintenancePhaseOneText?: { value?: string };
    maintenancePhaseTwoLabel?: { value?: string };
    maintenancePhaseTwoText?: { value?: string };
    maintenancePhaseThreeLabel?: { value?: string };
    maintenancePhaseThreeText?: { value?: string };
    maintenanceLink?: { value?: string };
    maintenanceText?: { value?: string };
    followUpStepsSectionElectricity?: OutageFollowUpStepsProps;
    followUpStepsSectionGas?: OutageFollowUpStepsProps;
  };
} & ComponentProps;

const OutageHero = (props: OutageHeroProps) => {
  return props.fields ? <OutageHeroComponent {...props.fields} /> : null;
};

export default withDatasourceCheck()<OutageHeroProps>(OutageHero);
