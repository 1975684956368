import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/cpBCwW2AKOMOQctXvl+nBZ7BssJEtK3Ta2a7uNk2LffUk1KSDOsEATxHB+coYcaj6K9b9w2dsDWFN9+62q7u5e/4e/qt/xLSaMf890JH07/cx1S3pC2MT1XHekGxObpZ3rnnS3BXSruR6UfaISNyl6o+itsn835el9DHN9p+TfK/sPij4q+r1S5/dKPnsl/w/KPh+VfT4p53pQ8jwo+lHRT4r+WdHPSv4Xxf+q+H9R/L8q+qOyz4300fPvXH9iHXbOjHP9mXRou12zm+tglMDAhG2XW7c1woDKIwP7I1cr1zgtiteiBOXgEJUbBozaZrVebwSywKyFFTQhCQPD5lfgfSMMnUI/aLgB87ZuNqutNAzTipDSRhjG72VcRVmSe62+zFxcv3VWZsXQbfzWrOUBmbqhH+PQCwNjN/gxjNLA3I3j6AZxr4DBG/uhz7Y6aCU5auc4aSsm+JZNBFkrpm9NK4ysFeF3PbeHy6k9D4erMGoMwpefrdJIhEft4t202/KkHfRZKQ0aBQcEJThqLzy0yunRKVVGrxkmFofYWxQGZtFgSj0IQ5ouXtgZuYJZpH1GFP0JW+VyI7O4tIZ+hIFYNKdbBYY/E3+c77v2TxsXFcKisovK1AbfLCYvB3WMMdA3a2uHCGV3cDZGeFmDWFsbLMeIdQqNsgQa2u5lCWDtEcKUFpkiKmte3EMdvOFUfE3P3qSyt0kmxhRes6pjiBimb0gDhnIS4wNiA29E0ahP7Q+X4VqZ6ZcXl2/JUHAs3jPqY3zKvr28H3rl9t7PfUpMIHU3d7oJca++UvDDj4ufmT5qsGhTBT5osBwUtvGoxdAmC/ysxdC6G140Jq4aEzxedH3fdIMwcFdrd303SiC5q41dj60YDHCaMGw3ZFs9TdwJ8Xl6wmLmNoVHypN7ykTqYobl1z9htmz+jtXrh/CgpubNH0L0hUx5gs+TiqWkqIlBfijqYIX41L4wj0W9C/Ng1Lgwj0Zk2jwaYWjzaMScy6MRZS7fl7jy+Q4EVsgzI6ZingPhlPJoxFKT7/vANcudD1y0XD5y1XKZEPrnfLztH9rq3fFru6iW5337aVFd6J19dxnOe/Hy4Kn91xYQckHCw7O8y8RrsajEGdTipcczfOGpPJZcb8WH/cSuojXx5J7fFmcKrg5K181hyZWI4negUF3JlSiCPIHArmJIdLHEhkslV8Yoz7V9cf3vfyHuM7j5DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/AnimatedArrow/AnimatedArrow.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/AnimatedArrow/AnimatedArrow.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA72VW4+iMBSA3/0VfdlEEzFcHEcx2cw/mRR6kK7QMm1x3Gzmvy8U5SaXgTX7YAL29DtfD6ft2xl+BwLHING79QvCQ8Id9GeBUCB4rB8QiiBQLjJezB/H7P0r+yneGCpHvhZvj7xtN68fZw3zXqbyjGHerptnzV3voc1TPOkpnx4ZpuFOWi9spHbeNNpI5fxOWmfh2ivd3BmmDki4pIpy5iLsSR6lCo6L5iQlMJMBF7FbPEZYwTL3W2vL1bEOfdVQn0dcuOiCxdIw3i1JHWY51iqnNRsDMxrjIn3VZI5pxhIBlmBQZvBUNTLs6xkoC0FQVYH7ubsRru0yrpYbnzMleBQBWbkhv4BAP1EZY61RBq+m1KI32Ff0Ao1obeph/3wSPGXE6C7Lh66LxwWBbND8nlRV8HGl1sfpqI3TVZtGSSc57Sc47fuctkNOVluK/HPfeSP9ATP7zp/bd9XaJpST9JXz8NxPDGv0fSnok8KTvnFQ7Ce9UQyBCU1lWfjqGBOQHVBZ+vzf3DmI+KeLQkoIsGPnftSnWoIFMFWedzdYOxiZG0fmQUEEV0OGgrJze8+exlpx9EQwGyeClVxRdjJT0gGrpQ3/U1q7mZb2p7Xn8CLN+6REhS5y7OSaM0Kgp1Dd32vRcT16azaji/fs/oyBUIykLwBY1oYELeOs2W6zXnf75LrSnBLLbndnS6MtUty05SzenHXXaQvdLvVBKcu0tw9WySyrjydabfPYlpWYZSWfaHWwH63ULKt0gtVfzAgg7cALAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var AnimatedArrow = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{size:{medium:{initial:'_1jeh9pol',sm:'_1jeh9pon',md:'_1jeh9pop',lg:'_1jeh9por',xl:'_1jeh9pot'},large:{initial:'_1jeh9pom',sm:'_1jeh9poo',md:'_1jeh9poq',lg:'_1jeh9pos',xl:'_1jeh9pou'}}},variantClassNames:{color:{onLight:{initial:'_1jeh9pog'},onDark:{initial:'_1jeh9poh'},ghostOnDark:{initial:'_1jeh9poi'}},direction:{leftToRight:{initial:'_1jeh9poj'},topToBottom:{initial:'_1jeh9pok'}}},compoundVariants:[],defaultVariants:{direction:'leftToRight',size:'medium',color:'onLight'},baseClassName:'_1jeh9pof'});
export var arrowWrap = '_1jeh9po2';
export var flyBase = '_1jeh9po0';
export var flyIn = '_1jeh9po7';
export var flyInAnimation = '_1jeh9po5';
export var flyInAnimationHover = '_1jeh9po3';
export var flyInTopToBottom = '_1jeh9pod';
export var flyInTopToBottomAnimation = '_1jeh9pob';
export var flyInTopToBottomAnimationHover = '_1jeh9po9';
export var flyOut = '_1jeh9po8';
export var flyOutAnimation = '_1jeh9po6';
export var flyOutAnimationHover = '_1jeh9po4';
export var flyOutTopToBottom = '_1jeh9poe';
export var flyOutTopToBottomAnimation = '_1jeh9poc';
export var flyOutTopToBottomAnimationHover = '_1jeh9poa';
export var innerWrap = '_1jeh9po1';