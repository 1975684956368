import * as Accordion from '@radix-ui/react-accordion';
import React from 'react';
import {
  ChevronDownIcon,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
  InstagramIcon,
} from '../../icons';
import { Box } from '../Box';
import { GridItem } from '../GridItem';
import { Heading } from '../Heading';
import { PageGrid } from '../PageGrid';
import { Stack } from '../Stack';
import { StackItem } from '../StackItem';
import { NavLink } from '../NavLink';
import { useMedia } from '../../hooks/useMedia';
import { mq } from '../../global/breakpoints';
import { Divider } from '../Divider';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './Footer.css';

export type FooterSocialLinks = Record<
  'facebook' | 'instagram' | 'linkedIn' | 'twitter',
  React.ReactElement
>;

type FooterProps = {
  sections: Array<{
    subHeader: string;
    links: React.ReactElement[];
  }>;
  copyright: Array<React.ReactElement>;
  socials: FooterSocialLinks;
};

export const FooterComponent = (props: FooterProps) => {
  const isDesktop = useMedia(mq.md);

  const socialLinks = Object.entries(props.socials) as Array<
    [keyof FooterSocialLinks, FooterSocialLinks[keyof FooterSocialLinks]]
  >;

  return (
    <footer>
      {isDesktop ? (
        <Box
          className={styles.footerWrapper}
          paddingTop={20}
          paddingBottom={20}
        >
          <PageGrid className={styles.grid}>
            {props.sections && props.sections.length > 0 && <SectionsDesktop sections={props.sections} />}
            <BottomBarDesktop
              copyright={props.copyright}
              socials={props.socials}
            />
          </PageGrid>
        </Box>
      ) : (
        <Box
          className={styles.footerWrapper}
          paddingTop={10}
          paddingBottom={10}
        >
          <PageGrid>
            <GridItem columnStart="1" columnEnd="-1">
              {props.sections && props.sections.length > 0 && (
                <AccordionMobileNav sections={props.sections} />
              )}
            </GridItem>

            {socialLinks && socialLinks.length > 0 ? (
              <GridItem columnStart="1" columnEnd="-1" asChild>
                <Stack
                  direction="row"
                  as="ul"
                  alignX="center"
                  gap={6}
                  className={styles.mobileSocialNav}
                >
                  {socialLinks.map(([key, item], index) => {
                    return (
                      <StackItem as="li" key={`${index}`}>
                        <NavLink
                          tone="onDark"
                          asChild
                          beforeIcon={iconMap(key)}
                        >
                          {item}
                        </NavLink>
                      </StackItem>
                    );
                  })}
                </Stack>
              </GridItem>
            ) : null}

            <GridItem columnStart="1" columnEnd="-1">
              <Stack
                direction="row"
                as="ul"
                className={styles.legalNavMobile}
                alignX="center"
                gap={4}
                wrap
              >
                {props.copyright.map((item, index) => (
                  <StackItem as="li" key={`${index}`}>
                    <NavLink tone="onDark" asChild>
                      {item}
                    </NavLink>
                  </StackItem>
                ))}
              </Stack>
            </GridItem>

            <GridItem columnStart="1" columnEnd="-1">
              <Stack alignX="center" isFullWidth>
                <StackItem className={styles.copyright}>
                  <span>
                    Copyright &copy; {new Date().getFullYear()} Liander
                  </span>
                </StackItem>
              </Stack>
            </GridItem>
          </PageGrid>
        </Box>
      )}
    </footer>
  );
};

type SectionsDesktopProps = {
  sections: Array<{
    subHeader: string;
    links: React.ReactElement[];
  }>;
};

const SectionsDesktop = (props: SectionsDesktopProps): JSX.Element => {
  return (
    <>
      {props.sections.map((section) => (
        <GridItem columnStart="auto" columnEnd="span 3" key={section.subHeader}>
          <Stack direction="column">
            <Heading size="h5" asChild>
              <Box paddingBottom={20} asChild>
                <h3 id={section.subHeader}>{section.subHeader}</h3>
              </Box>
            </Heading>
            <Stack as="ul" gap={6}>
              {section.links.map((item, i) => (
                <StackItem as="li" key={`desktopsection-${i}`}>
                  <NavLink
                    tone="onDark"
                    className={styles.footerNavLink}
                    asChild
                  >
                    {item}
                  </NavLink>
                </StackItem>
              ))}
            </Stack>
          </Stack>
        </GridItem>
      ))}
    </>
  );
};

type BottomBarDesktopProps = {
  copyright: Array<React.ReactElement>;
  socials: FooterSocialLinks;
};

const BottomBarDesktop = (props: BottomBarDesktopProps): JSX.Element => {
  const socialLinks = Object.entries(props.socials) as Array<
    [keyof FooterSocialLinks, FooterSocialLinks[keyof FooterSocialLinks]]
  >;

  return (
    <GridItem columnStart="1" columnEnd="-1" asChild>
      <Stack direction="row" alignY="center" gap={10} alignX="justify">
        <Stack direction="row" as="ul" gap={6} alignX="start">
          <StackItem className={styles.copyright} as="li">
            <span>Copyright &copy; {new Date().getFullYear()} Liander</span>
          </StackItem>
          {props.copyright.map((item, index) => (
            <StackItem as="li" key={'copyright' + index}>
              <NavLink tone="onDark" asChild>
                {item}
              </NavLink>
            </StackItem>
          ))}
        </Stack>

        {socialLinks.length > 0 ? (
          <Stack direction="row" as="ul" gap={6} alignX="end">
            {socialLinks.map(([title, item], index) => (
              <StackItem as="li" key={`desktopSocialLink-${index}`}>
                <NavLink
                  tone="onDark"
                  className={styles.footerNavLink}
                  beforeIcon={iconMap(title)}
                  asChild
                >
                  {item}
                </NavLink>
              </StackItem>
            ))}
          </Stack>
        ) : null}
      </Stack>
    </GridItem>
  );
};

type AccordionMobileNavProps = {
  sections: Array<{
    subHeader: string;
    links: React.ReactElement[];
  }>;
};

const AccordionMobileNav = (props: AccordionMobileNavProps): JSX.Element => {
  return (
    <Box width="100%" asChild>
      <Accordion.Root type="single" collapsible>
        {props.sections.map((section, i) => {
          return (
            <Accordion.Item
              value={`footer-nav-item--${section.subHeader}`}
              className={styles.mobileNavItem}
              key={`footer-nav-item--${section.subHeader}`}
            >
              {i !== 0 ? <Divider tone="onDark" /> : null}
              <Accordion.Header>
                <ToneFocus tone="onDark">
                  <Heading size="h5" isResponsive asChild>
                    <Accordion.Trigger className={styles.trigger}>
                      {section.subHeader}
                      <ChevronDownIcon className={styles.triggerIcon} />
                    </Accordion.Trigger>
                  </Heading>
                </ToneFocus>
              </Accordion.Header>
              <Accordion.Content>
                <Stack
                  direction="column"
                  as="ul"
                  gap={6}
                  className={styles.mobileNavList}
                >
                  {section.links.map((item, i) => (
                    <StackItem as="li" key={`mobilecollapse${i}-`}>
                      <NavLink
                        tone="onDark"
                        className={styles.footerNavLink}
                        asChild
                      >
                        {item}
                      </NavLink>
                    </StackItem>
                  ))}
                </Stack>
              </Accordion.Content>
            </Accordion.Item>
          );
        })}
      </Accordion.Root>
    </Box>
  );
};

function iconMap(text: keyof FooterSocialLinks): React.ReactElement | null {
  switch (text) {
    case 'facebook':
      return <FacebookIcon size="lg" />;

    case 'linkedIn':
      return <LinkedinIcon size="lg" />;

    case 'twitter':
      return <XIcon size="lg" />;

    case 'instagram':
      return <InstagramIcon size="lg" />;

    default:
      return null;
  }
}
