import clsx from 'clsx';
import { PersonPinIcon } from '../../icons';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { Text, TextProps } from '../Text';
import { mq } from '../../global/breakpoints';
import { useMedia } from '../../hooks/useMedia';

import * as styles from './ProgressDetails.css';

type ProgressDetailsProps = {
  currentStatus: string;
  statusOptions?: string[];
};

export const ProgressDetails = (props: ProgressDetailsProps) => {
  const { currentStatus, statusOptions } = props;
  const isDesktop = useMedia(mq.lg);

  if(!statusOptions) {
    return null
  }

  const currentProgressIndex = statusOptions.indexOf(currentStatus);

  const onePercent = 100 / statusOptions.length;
  const calculationProgressWidth =
    onePercent * currentProgressIndex + onePercent / 2;

  const progress =
    currentProgressIndex !== statusOptions.length - 1
      ? calculationProgressWidth
      : 100;

  return (
    <Stack gap={1} isFullWidth asChild>
      <div role="region" aria-labelledby="progressCurrentStatus">
        <Box paddingTop={5}>
          <Box
            bg="containerPrimaryLight1"
            padding={1}
            height="14px"
            width="100%"
            position="relative"
            borderRadius="lg"
          >
            <Box
              className={styles.progressBar}
              bg="containerPrimary"
              height="6px"
              style={{
                '--progress': currentProgressIndex ? `${progress}%` : '6px',
              }}
              borderRadius="md"
              position="relative"
            >
              <Box position="absolute" asChild>
                <PersonPinIcon
                  className={clsx(styles.ProgressIcon, {
                    [styles.ProgressIconAnimated]: currentProgressIndex !== 0,
                  })}
                  color="highlight"
                  size={isDesktop ? 'sm' : 'xs'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={styles.ProgressLabels}
          style={{ '--columns': statusOptions.length }}
        >
          {statusOptions.map((option, index) => (
            <Text
              className={styles.ProgressLabel}
              size="label"
              key={`${index}-${option}`}
              align={textAlignment(index, statusOptions.length - 1)}
              asChild
            >
              <span
                id={
                  option === currentStatus ? 'progressCurrentStatus' : undefined
                }
              >
                {option}
              </span>
            </Text>
          ))}
        </Box>
      </div>
    </Stack>
  );
};

const textAlignment = (
  index: number,
  max: number
): Extract<TextProps['align'], string> => {
  switch (index) {
    case 0:
      return 'start';
    case max:
      return 'end';
    default:
      return 'center';
  }
};
