import React from 'react';

import { Box, Loader, Stack } from '@ads-core/components';

import * as styles from './PolygonMap.css';

// Import the function to set the Arcgis Map.
async function loadMap(
  container: HTMLDivElement,
  rings: number[][][] | undefined,
  postalCode: string | undefined
) {
  const { initializeArcgisMap } = await import('./initializeArcgisMap');
  return initializeArcgisMap(container, rings, postalCode);
}

type PolygonMapProps = {
  isLoading?: boolean;
  postalCode?: string;
  rings?: number[][][];
};

export const PolygonMap = ({ postalCode, rings, isLoading }: PolygonMapProps) => {
  const mapRef = React.useRef<HTMLDivElement>(null);
  const id = React.useId();

  React.useEffect(() => {
    if (!isLoading && mapRef.current) {
      loadMap(mapRef.current, rings, postalCode);
    }
  }, [mapRef, isLoading, rings, postalCode]);

  return (
    <Box
      overflow="hidden"
      width="100%"
      height="100%"
      bg="backgroundBackdrop"
      className={styles.polygonMap}
    >
      {isLoading ? (
        <Stack isFullHeight isFullWidth alignY="center" alignX="center">
          <Loader />
        </Stack>
      ) : null}
      <div id={`map-${id}`} ref={mapRef} style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};
