import React from 'react';
import clsx from 'clsx';
import { Heading } from '../Heading';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Box } from '../Box';
import { mq } from '../../global/breakpoints';
import { useMedia } from '../../hooks/useMedia';
import * as styles from './ListHero.css';

export const ListHeroView = (props: ListHeroProps) => {
  const isDesktop = useMedia(mq.md);
  const hasExplanation = !!props.explanation;
  const hasList = props.list;
  const isBreakout = props.variant === 'breakout';

  return isDesktop ? (
    <div>
      <PageGrid
        className={clsx('ads-ListHero', styles.heroWrapper, {
          [styles.heroBreakOutWrapper]: isBreakout,
        })}
      >
        {props.text && isBreakout ? (
          <GridItem
            columnStart={{ initial: '1', md: hasExplanation ? '1' : '3' }}
            columnEnd={{ initial: '-1', md: hasExplanation ? 'span 6' : '-3' }}
          >
            <Heading size={'h1'}>{props.text}</Heading>
          </GridItem>
        ) : null}

        {props.text && !isBreakout ? (
          <GridItem
            columnStart={{ initial: '1', md: hasExplanation ? '2' : '3' }}
            columnEnd={{ initial: '-1', md: hasExplanation ? 'span 6' : '-3' }}
          >
            <Heading size={'h5'} as={'h3'}>
              {props.text}
            </Heading>
          </GridItem>
        ) : null}
        {hasExplanation ? (
          <GridItem
            columnStart={{ initial: '1', md: 'span 4' }}
            columnEnd={{ initial: '-1', md: isBreakout ? '-1' : '-2' }}
            asChild
          >
            {props.explanation}
          </GridItem>
        ) : null}

        {hasList ? (
          <GridItem
            columnStart={isBreakout ? '1' : '2'}
            columnEnd={isBreakout ? '-1' : '-2'}
            asChild
          >
            <Box
              position="relative"
              zIndex="4"
              className={styles.list}
              width="100%"
            >
              {props.list}
            </Box>
          </GridItem>
        ) : null}
      </PageGrid>

      {props.image ? (
        <PageGrid style={isBreakout ? { '--gutter': '0' } : undefined}>
          <GridItem columnStart={'1'} columnEnd={'-1'} asChild>
            <Box
              width="100%"
              className={clsx(styles.image, styles.ImageWrapper)}
              asChild
            >
              {props.image}
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
    </div>
  ) : (
    <div>
      <PageGrid className={styles.heroWrapper}>
        {props.text ? (
          <GridItem
            columnStart={{ initial: '1', md: hasExplanation ? '1' : '3' }}
            columnEnd={{ initial: '-1', md: hasExplanation ? 'span 6' : '-3' }}
          >
            <Box width="100%" asChild>
              <Heading size={isBreakout ? 'h1' : 'h3'}>{props.text}</Heading>
            </Box>
          </GridItem>
        ) : null}
        {hasExplanation && isBreakout ? (
          <GridItem
            columnStart={{ initial: '1', md: 'span 4' }}
            columnEnd={{ initial: '-1', md: '-1' }}
          >
            <Box width="100%" asChild>
              {props.explanation}
            </Box>
          </GridItem>
        ) : null}

        {hasList ? (
          <GridItem
            columnStart={{ initial: '1', md: 'span 4' }}
            columnEnd={{ initial: '-1', md: '-1' }}
            asChild
          >
            <Box
              className={clsx(styles.list, {
                [styles.listBreakout]: isBreakout,
              })}
              position="relative"
              zIndex="4"
            >
              {props.list}
            </Box>
          </GridItem>
        ) : null}

        {hasExplanation && !isBreakout ? (
          <GridItem
            columnStart={{ initial: '1', md: 'span 4' }}
            columnEnd={{ initial: '-1', md: '-1' }}
          >
            <Box width="100%" asChild>
              {props.explanation}
            </Box>
          </GridItem>
        ) : null}
      </PageGrid>

      {props.image && isBreakout ? (
        <Box className={styles.ImageWrapper} width="100vw">
          <Box width="100%" height="100%" asChild className={styles.image}>
            {props.image}
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

type ListHeroProps = {
  image?: React.ReactElement;
  text?: string;
  explanation?: React.ReactElement;
  list?: React.ReactElement | null;
  variant?: 'contained' | 'breakout';
};
