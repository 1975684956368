import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/cpBCwW2AKOMOQctXvl+nBZ7BssJEtK3Ta2a7uNk2LffUk1KSDOsEATxHB+coYcaj6K9b9w2dsDWFN9+62q7u5e/4e/qt/xLSaMf890JH07/cx1S3pC2MT1XHekGxObpZ3rnnS3BXSruR6UfaISNyl6o+itsn835el9DHN9p+TfK/sPij4q+r1S5/dKPnsl/w/KPh+VfT4p53pQ8jwo+lHRT4r+WdHPSv4Xxf+q+H9R/L8q+qOyz4300fPvXH9iHXbOjHP9mXRou12zm+tglMDAhG2XW7c1woDKIwP7I1cr1zgtiteiBOXgEJUbBozaZrVebwSywKyFFTQhCQPD5lfgfSMMnUI/aLgB87ZuNqutNAzTipDSRhjG72VcRVmSe62+zFxcv3VWZsXQbfzWrOUBmbqhH+PQCwNjN/gxjNLA3I3j6AZxr4DBG/uhz7Y6aCU5auc4aSsm+JZNBFkrpm9NK4ysFeF3PbeHy6k9D4erMGoMwpefrdJIhEft4t202/KkHfRZKQ0aBQcEJThqLzy0yunRKVVGrxkmFofYWxQGZtFgSj0IQ5ouXtgZuYJZpH1GFP0JW+VyI7O4tIZ+hIFYNKdbBYY/E3+c77v2TxsXFcKisovK1AbfLCYvB3WMMdA3a2uHCGV3cDZGeFmDWFsbLMeIdQqNsgQa2u5lCWDtEcKUFpkiKmte3EMdvOFUfE3P3qSyt0kmxhRes6pjiBimb0gDhnIS4wNiA29E0ahP7Q+X4VqZ6ZcXl2/JUHAs3jPqY3zKvr28H3rl9t7PfUpMIHU3d7oJca++UvDDj4ufmT5qsGhTBT5osBwUtvGoxdAmC/ysxdC6G140Jq4aEzxedH3fdIMwcFdrd303SiC5q41dj60YDHCaMGw3ZFs9TdwJ8Xl6wmLmNoVHypN7ykTqYobl1z9htmz+jtXrh/CgpubNH0L0hUx5gs+TiqWkqIlBfijqYIX41L4wj0W9C/Ng1Lgwj0Zk2jwaYWjzaMScy6MRZS7fl7jy+Q4EVsgzI6ZingPhlPJoxFKT7/vANcudD1y0XD5y1XKZEPrnfLztH9rq3fFru6iW5337aVFd6J19dxnOe/Hy4Kn91xYQckHCw7O8y8RrsajEGdTipcczfOGpPJZcb8WH/cSuojXx5J7fFmcKrg5K181hyZWI4negUF3JlSiCPIHArmJIdLHEhkslV8Yoz7V9cf3vfyHuM7j5DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/Table/table.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/Table/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51VTW+jMBC951dYlVY02oXYQAklTbTaUw/pNYdeKoMNuEswa0xCt+p/X+NgGtJErfYEjN+befPB2HlCc+9P1voQvE4A2DMi8wggCL8tJm8Tx5wifcp3VKQF39ttBHAj+QjiaoikrbRxwbIyAgVN5ULZEl5wEYEdFte2/YRq5pXIQ9MR29PsitdMMq64ghZYsh0dgXwN2rLS7nW6EFZtF2KLW2PzD7Yj2o2mxVwQKuyYS8m3KsWqBTUvGDnR5bLpVzUH2m/SiLqDVpyVkorF52nMNS8TjNgqTLMt7VpiIVU6h1RExhQVjjghWAFCogLX0k5yVpBDvTAhrMxOwbeXAqARDH9s+aiSxpRTluXy/ZvHzzSRdsqULelmYuQ1vthJAHBddUyhDFz5A7MTRXGUd/7O1/UjLsKpNGiuQKUSdHXVBYpx8jsTvCl1Bc500tNd/iTR9yRwrGalkToJyStdcKAHvH/lFU6YfFFfjn9WakxTLuhYayOK6yuCJY7YFmd0Vu+y7+22WCQ5FjWVy0amdvjjTpmBMpf10sqlrKLZbL/fO3vP4SKbqV8AdkTrkMzScn2rT+TwvmN0/4u3SwsCCFwfdLaUFcXSKnlJrdVdhWUOyNJ6QAGAa3TrwCCYA697BPM1unFc5HkgcMJbz/PWaO4gqM5DZx525y50Ojvw9cNbqwjhBt6j4PEBgnDduxmOezcDvX8O7nvcED4E8B5uwseHUAkfTvugA7sXNXjvRQ/R+6TWEKBg4/r3yp/SiYJBvQGY7IwDk70JYKpjBKiSKXeuv1H5mrLucyapNVvddW1ZXelB+2uzktBW/4H/OXaE1VWB1YSlBdU777mpJUtf7GGcEmoWkF7AtpKxrd/NR0OZfNgeZj+erpKbKGVi2DnHNNv8ByP00YI62rp9CMM0O3hMnl/YbhfgEEgSlVxeH9GmAH/lHoHgvOLX0zL83FLCMKgTQWkJcEnA9dHtg3xfXTVTTXPG96jaEjguqK0axhvVmpS1lHQleJu8/QM3VU4NcwcAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var clickableRow = '_173qgx46';
export var description = '_173qgx47';
export var descriptionsList = '_173qgx48';
export var descriptionsTerm = '_173qgx49';
export var heading = '_173qgx42';
export var image = '_173qgx4a';
export var imageTrigger = '_173qgx4c';
export var imageWrapper = '_173qgx4b';
export var tableContainer = '_173qgx40';
export var tableContainerScrollable = '_173qgx41';
export var tableDescription = '_173qgx43';
export var tableDescriptionScrollable = '_173qgx44';
export var tableRow = '_173qgx45';