import React from 'react';
import { InputNumber, InputText, Stack, StackItem, Alert, RichText } from '@ads-core/components';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse } from '@alliander-fe/api/data-contracts';
import {
  getOptions,
  isValidValidationModel,
  validationModels,
  getEnabledValidation,
} from '@alliander-fe/validation';
import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { extractString, encodeNameToReactHookFormFormat, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { AddressPropsWithConditions, InputViewWithParameters } from '../types';

type Props = AddressPropsWithConditions<
  ValueFieldProps<InputViewWithParameters<StringInputViewModel>>
>;

export const AddressBlockFieldMapper = ({ field }: Props) => {
  const { formState, getValues, register, resetField } = useFormContext();
  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const { fetchStatus, isNetworkOperator, getAddress } = useGetAddress(name);
  const id = React.useId();
  const warningMessageId = `${id}-addressBlockWarning`;
  useSetComputedAddressField({ name }); // set computed value based on other values

  const { fieldKey } = field.model.conditionSettings;
  const networkOperatorCheck = field.model.enableNetworkOperatorCheck;

  const warningMessage = () => {
    if (fetchStatus === 'notFound') {
      return '<p>We hebben uw adres niet gevonden. Controleer uw gegevens of voer uw adres handmatig in.</p>';
    }

    if (networkOperatorCheck && !isNetworkOperator) {
      return '<p>We zien aan uw adres dat Liander niet uw netbeheerder is. Controleer uw gegevens of zoek uw netbeheerder op via <a href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">mijnaansluiting.nl</a>.</p>';
    }
  };

  const names = getNames(name);
  // Extract the errors in a single function call so we memoize the result
  const errors = React.useMemo(
    () =>
      Object.entries(names).reduce((acc, [key, formKey]: [keyof Names, string]) => {
        acc[key] = extractString(formState.errors[formKey]?.message);
        return acc;
      }, {} as Errors),
    [formState.errors, names]
  );

  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const options = getOptions(field, ['required']);

  const isAdresBlockPostalCode = getEnabledValidation(
    validationModels.IS_ADRES_BLOCK_POSTAL_CODE,
    field.model.validationDataModels
  );

  const postalCodeMethods = register(names.postalCode, {
    ...options,
    validate: {
      validationModel: (v) => {
        if (isAdresBlockPostalCode && v) {
          return isValidValidationModel(isAdresBlockPostalCode, v);
        }

        return true;
      },
    },
  });

  const houseNumberMethods = register(names.houseNumber, options);
  const addendumMethods = register(names.addendum);
  const cityMethods = register(names.city, options);
  const streetMethods = register(names.street, options);

  const isFetching = fetchStatus === 'loading';
  const streetAndPlacePlaceholder = isFetching ? 'Bezig met ophalen van adresgegevens...' : '';

  return (
    <Stack gap={6} aria-describedby={warningMessageId}>
      <InputText
        tone="onLight"
        label={getLabel('Postcode', !!options.required)}
        placeholder="1234AB"
        error={errors.postalCode}
        {...postalCodeMethods}
        onBlur={(e) => {
          postalCodeMethods.onBlur(e);
          const currentHouseNumber = getValues(names.houseNumber);
          getAddress(currentHouseNumber, e.target.value);
        }}
        onChange={(e) => {
          postalCodeMethods.onChange(e);
          resetField(names.city);
          resetField(names.street);
        }}
      />
      <Stack
        direction={{
          initial: 'column',
          sm: 'row',
        }}
        gap={6}
        isFullWidth
      >
        <StackItem grow>
          <InputNumber
            tone="onLight"
            label={getLabel('Huisnummer', !!options.required)}
            {...houseNumberMethods}
            error={errors.houseNumber}
            onBlur={(e) => {
              houseNumberMethods.onBlur(e);
              const currentPostalCode = getValues(names.postalCode);
              getAddress(e.target.value, currentPostalCode);
            }}
            onChange={(e) => {
              houseNumberMethods.onChange(e);
              resetField(names.city);
              resetField(names.street);
            }}
          />
        </StackItem>
        <StackItem grow>
          <InputText
            tone="onLight"
            label={getLabel('Toevoeging', false)}
            error={errors.addendum}
            {...addendumMethods}
          />
        </StackItem>
      </Stack>
      <InputText
        disabled={isFetching}
        placeholder={streetAndPlacePlaceholder}
        error={errors.street}
        tone="onLight"
        label={getLabel('Straat', !!options.required)}
        {...streetMethods}
      />
      <InputText
        disabled={isFetching}
        placeholder={streetAndPlacePlaceholder}
        error={errors.city}
        tone="onLight"
        label={getLabel('Plaats', !!options.required)}
        {...cityMethods}
      />

      <Alert variant="warning" id={warningMessageId} role="alert">
        {warningMessage() ? <RichText tone="onLight">{warningMessage()}</RichText> : null}
      </Alert>
    </Stack>
  );
};

function getNames(name: string) {
  return {
    postalCode: `postalCode_${name}`,
    houseNumber: `houseNumber_${name}`,
    addendum: `addendum_${name}`,
    city: `city_${name}`,
    street: `street_${name}`,
  };
}

type Names = ReturnType<typeof getNames>;
type Errors = { [K in keyof Names]: Names[K] | undefined };

type FetchStatus = 'initial' | 'loading' | 'done' | 'error' | 'notFound';
function useGetAddress(name: string) {
  const names = getNames(name);
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial');
  const [isNetworkOperator, setIsNetworkOperator] = React.useState<boolean>(true);
  const { setValue, resetField } = useFormContext();

  const getAddress = async (houseNumber?: string, postalCode?: string) => {
    const currentHouseNumber = houseNumber;
    const currentPostalCode = postalCode;

    if (currentHouseNumber && currentPostalCode) {
      try {
        setFetchStatus('loading');
        setIsNetworkOperator(true);

        const req = await fetch(
          `/api/service-availability/details/${currentPostalCode.toString()}/${currentHouseNumber.toString()}`
        );

        if (req.status === 404) {
          setFetchStatus('notFound');
          return;
        }

        const res: IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse =
          await req.json();

        if (req.status === 200) {
          resetField(names.city);
          resetField(names.street);

          const city = res.address?.city;
          const street = res.address?.street || '';

          const formattedCity = city
            ? city.charAt(0).toUpperCase() + city.slice(1).toLowerCase()
            : '';

          setValue(names.city, formattedCity);
          setValue(names.street, street);

          // Check if this address is within the network area
          if (!res.electricityNetwork?.isOperational && !res.gasNetwork?.isOperational) {
            setIsNetworkOperator(false);
          }
        }
        setFetchStatus('done');
      } catch (err) {
        setFetchStatus('error');
        resetField(names.city);
        resetField(names.street);
      }
    }
  };

  return { fetchStatus, isNetworkOperator, getAddress };
}
/**
 * Sitecore needs one computed field in the format: 2727CD|157|A|Zoetermeer|Velddreef. This hook automatically computes that based off other values.
 * @param param0
 */
function useSetComputedAddressField({ name }: { name: string }) {
  const { watch, setValue } = useFormContext();
  const names = getNames(name);
  const value = watch(Object.values(names)).join('|');

  React.useEffect(() => {
    setValue(name, value);
  }, [name, setValue, value]);
}
